import { AxiosResponse } from 'axios';
import {
  CashFlow,
  CashFlowHistory,
  CashFlowHistoryFilters,
  CashFlowHistoryRequestParams,
  CloseCashParams,
  MovementRequestParams,
} from '../models/cashFlow';
import Api from './api';

const baseUrl = '/api/Cashflow';

function getCashFlowHistory(
  cashFlowHistoryRequestParams: CashFlowHistoryRequestParams
): Promise<AxiosResponse<CashFlowHistory>> {
  const params = cashFlowHistoryRequestParams;

  const url = `${baseUrl}/history?page=${params.currentPage ?? 1}&pageSize=${
    params.pageSize ?? 10
  }&startDate=${params.startDate ?? ''}&finalDate=${
    params.endDate ?? ''
  }&userGuid=${params.userGuid ?? ''}`;

  return Api.get(url, {
    headers: {
      authHeader: true,
      'x-api-version': 2,
    },
  }).then((res) => res.data);
}

function getCashFlowHistoryFilters(): Promise<
  AxiosResponse<CashFlowHistoryFilters>
> {
  return Api.get(`${baseUrl}/Filters`, {
    headers: {
      authHeader: true,
      'x-api-version': 2,
    },
  }).then((res) => res.data);
}

function getCashFlowDetails(
  cashFlowGuid: string
): Promise<AxiosResponse<CashFlow>> {
  return Api.get(`${baseUrl}/${cashFlowGuid}`, {
    headers: {
      authHeader: true,
      'x-api-version': 2,
    },
  });
}

function closeCash(balanceReported: CloseCashParams): Promise<AxiosResponse> {
  return Api.post(`${baseUrl}/close`, balanceReported, {
    headers: {
      authHeader: true,
      successMessage: 'Caixa fechado com sucesso.',
    },
  });
}

function addNewMovement(
  movementInfo: MovementRequestParams
): Promise<AxiosResponse> {
  return Api.post(`${baseUrl}/cashMovement`, movementInfo, {
    headers: {
      authHeader: true,
      successMessage: 'Nova movimentação inserida.',
    },
  });
}

function deleteMovement(movementGuid: string): Promise<AxiosResponse> {
  return Api.delete(`${baseUrl}/cashMovement/${movementGuid}`, {
    headers: {
      authHeader: true,
      successMessage: 'Movimentação excluída com sucesso.',
    },
  });
}

export const cashFlowServices = {
  getCashFlowHistory,
  getCashFlowHistoryFilters,
  getCashFlowDetails,
  addNewMovement,
  closeCash,
  deleteMovement,
};
