/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CashFlowState } from '../../types';
import {
  fetchCashFlowConfig,
  fetchCashFlowDetails,
  fetchCashFlowHistory,
  fetchCashFlowHistoryFilters,
  fetchOpenedCash,
  updateCashFlowConfig,
} from './actions';

const initialState: CashFlowState = {};

export const cashFlowSlice = createSlice({
  name: 'cashflow',
  initialState,
  reducers: {
    resetOpenedCashFlow(state) {
      state.openedCashFlow = undefined;
    },

    setOpenedCashFlow(state, { payload }) {
      state.openedCashFlow = payload;
    },

    clearFetchCashHistoryStatus(state) {
      state.fetchCashHistoryStatus = undefined;
    },
  },
  extraReducers: {
    [fetchCashFlowHistory.pending.type]: (state) => {
      state.fetchCashHistoryStatus = 'pending';
    },
    [fetchCashFlowHistory.fulfilled.type]: (state, { payload }) => {
      state.history = payload;
      state.fetchCashHistoryStatus = 'fulfilled';
    },
    [fetchCashFlowHistory.rejected.type]: (state) => {
      state.fetchCashHistoryStatus = 'rejected';
    },
    [fetchCashFlowHistoryFilters.fulfilled.type]: (state, { payload }) => {
      state.historyFilters = payload;
    },
    [fetchOpenedCash.fulfilled.type]: (state, { payload }) => {
      state.openedCashFlow = payload;
    },
    [fetchCashFlowDetails.fulfilled.type]: (state, { payload }) => {
      state.cashFlowDetails = payload;
    },
    [fetchCashFlowConfig.fulfilled.type]: (state, { payload }) => {
      state.config = payload;
    },
    [updateCashFlowConfig.fulfilled.type]: (state, { payload }) => {
      state.config = payload;
    },
  },
});

export default cashFlowSlice.reducer;
export const {
  resetOpenedCashFlow,
  setOpenedCashFlow,
  clearFetchCashHistoryStatus,
} = cashFlowSlice.actions;
