/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { ReportsState } from '../../types';
import {
  fetchDeliveriesReports,
  fetchGeneralReports,
  fetchItemsReports,
  fetchPersonsReports,
  fetchPizzaReports,
  fetchSurveyGeneralReports,
  fetchSurveyReports,
} from './actions';

const initialState: ReportsState = {
  general: {
    startDate: moment().format('YYYY-MM-DD[T00:00:00]'),
    endDate: moment().format('YYYY-MM-DD[T00:00:00]'),
  },
  items: {
    startDate: moment().format('YYYY-MM-DD[T00:00:00]'),
    endDate: moment().format('YYYY-MM-DD[T00:00:00]'),
  },
  deliveries: {
    startDate: moment().format('YYYY-MM-DD[T00:00:00]'),
    endDate: moment().format('YYYY-MM-DD[T00:00:00]'),
  },
  survey: {
    startDate: moment().format('YYYY-MM-DD[T00:00:00]'),
    endDate: moment().format('YYYY-MM-DD[T00:00:00]'),
  },
  pizzas: {
    startDate: moment().format('YYYY-MM-DD[T00:00:00]'),
    endDate: moment().format('YYYY-MM-DD[T00:00:00]'),
  },
};

export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGeneralReports.fulfilled.type]: (state, { payload }) => {
      state.general = payload;
    },
    [fetchItemsReports.fulfilled.type]: (state, { payload }) => {
      state.items = payload;
    },
    [fetchPersonsReports.fulfilled.type]: (state, { payload }) => {
      state.persons = payload;
    },
    [fetchDeliveriesReports.fulfilled.type]: (state, { payload }) => {
      state.deliveries = payload;
    },
    [fetchSurveyReports.fulfilled.type]: (state, { payload }) => {
      state.survey = payload;
    },
    [fetchSurveyGeneralReports.fulfilled.type]: (state, { payload }) => {
      state.surveyGeneralReport = payload;
    },
    [fetchPizzaReports.fulfilled.type]: (state, { payload }) => {
      state.pizzas = payload;
    },
  },
});

export default reportSlice.reducer;
