import {
  IFoodConfiguration,
  IFoodMerchant,
  IFoodOrderCancellationReason,
} from '@wls-solucoes/lets-eat-types';
import Api from './api';

const base = '/api/integrations/ifood';

async function getConfiguration(): Promise<IFoodConfiguration> {
  return Api.get(base, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function enableIntegration(merchantGuid: string): Promise<IFoodMerchant> {
  return Api.post(`${base}/merchants`, undefined, {
    params: { merchantGuid },
    headers: {
      authHeader: true,
      'x-api-version': 3,
    },
  });
}

async function deleteIntegration(merchantGuid: string): Promise<IFoodMerchant> {
  return Api.delete(`${base}/merchants/guid:${merchantGuid}`, {
    headers: {
      authHeader: true,
      'x-api-version': 3,
      successMessage: 'Integração removida com sucesso!',
    },
  });
}

async function getOrderCancellationReasons(
  guid: string
): Promise<IFoodOrderCancellationReason[]> {
  return Api.get(`${base}/orders/${guid}/cancellation-reasons`, {
    headers: {
      authHeader: true,
      'x-api-version': 3,
    },
  }).then((res) => res.data);
}

async function linkCustomerWithPerson(
  ifoodCustomerGuid: string,
  personGuid: string
): Promise<IFoodOrderCancellationReason[]> {
  return Api.post(
    `${base}/customers/${ifoodCustomerGuid}/link-with-person/${personGuid}`,
    undefined,
    {
      headers: {
        authHeader: true,
        'x-api-version': 3,
        successMessage: 'Cliente associado!',
      },
    }
  ).then();
}

export const iFoodService = {
  getConfiguration,
  enableIntegration,
  deleteIntegration,
  getOrderCancellationReasons,
  linkCustomerWithPerson,
};
