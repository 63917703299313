import { createAsyncThunk } from '@reduxjs/toolkit';
import { GenericStatus } from '@wls-solucoes/lets-eat-types';
import { categoryService, productService } from '../../../shared/services/api';
import {
  addCategoryOnLoading,
  addItemOnLoading,
  removeCategoryOnLoading,
  removeItemOnLoading,
} from './reducer';

export const fetchCategories = createAsyncThunk(
  'productV3/fetchCategories',
  async (payload: { excludeEmpty?: boolean }) => {
    return categoryService.getAll().then((data) => {
      if (payload?.excludeEmpty)
        return data.filter((category) => category.itemsCount > 0);

      return data;
    });
  }
);

export const fetchSimplifiedCategories = createAsyncThunk(
  'productV3/fetchSimplifiedCategories',
  async () => {
    return productService.getSimplifiedCategories();
  }
);

export const filterCategories = createAsyncThunk(
  'productV3/filterCategories',
  async ({
    categoryGuid,
    query,
    excludeEmpty,
  }: {
    categoryGuid?: string;
    query?: string;
    excludeEmpty?: boolean;
  }) => {
    if (categoryGuid || query) {
      return categoryService
        .getByFilters({
          categoryGuid,
          query,
        })
        .then((data) => {
          if (excludeEmpty)
            return data.result.filter((category) => category.itemsCount > 0);

          return data.result;
        });
    }

    return categoryService.getAll().then((data) => {
      if (excludeEmpty)
        return data.filter((category) => category.itemsCount > 0);

      return data;
    });
  }
);

export const fetchBlockedItems = createAsyncThunk(
  'productV3/fetchBlockedItems',
  async () => {
    return productService.getBlockedItems();
  }
);

export const changeCategoryStatus = createAsyncThunk(
  'productV3/changeCategoryStatus',
  async (params: { guid: string; status: GenericStatus }, { dispatch }) => {
    dispatch(addCategoryOnLoading(params.guid));

    return categoryService
      .changeStatus(params.guid, params.status)
      .finally(() => {
        dispatch(removeCategoryOnLoading(params.guid));
      });
  }
);

export const changeItemStatus = createAsyncThunk(
  'productV3/changeItemStatus',
  async (guid: string, { dispatch }) => {
    dispatch(addItemOnLoading(guid));

    return productService.changeVisibility(guid).finally(() => {
      dispatch(removeItemOnLoading(guid));
    });
  }
);
