import queryString from 'query-string';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { appHistory } from '../../helpers/utils/appHistory';
import { userLogin } from '../../redux/reducers/user/userActions';
import RouteProgressIndicator from '../../shared/components/RouteProgressIndicator';
import { LOGIN } from '../../shared/models/eventGA';
import { analytics } from '../../shared/services/analytics';

const AuthenticatePage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = queryString.parse(location.search) || {};

  const loginWithRegisterToken = async (
    token?: string
  ): Promise<void> => {
    const data = (await dispatch(
      userLogin(undefined, undefined, token)
    )) as any;

    if (data?.accessToken) {
      appHistory.replace('/panel/first-access');
      analytics.eventsGA(LOGIN, data.establishments[0].guid, {method: 'Email'});
    }
  };

  React.useEffect(() => {
    if (queryParams) {
      if (queryParams.tk) {
        loginWithRegisterToken(
          queryParams.tk === null ? undefined : (queryParams.tk as string)
        );
      } else {
        appHistory.push('/login');
      }
    }
  }, [queryParams]); // eslint-disable-line

  return (
    <div>
      <RouteProgressIndicator />
    </div>
  );
};

export default AuthenticatePage;
