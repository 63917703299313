import { makeExternalData } from '@wls-solucoes/lets-eat-core';

interface ExternalData {
  hideAppBar: boolean;
  hideChatButton: boolean;
  disableOneSignal: boolean;
  enableExternalLinks: boolean;
  enableParentalLinks: boolean;
  theme: 'light' | 'dark';
}

export const useExternalData = makeExternalData<ExternalData>();
