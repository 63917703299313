import {localAddressServices} from "../../../shared/services/localAddress";
import {GenericAction, LOCAL_ADDRESS_ADD, LocalAddressState} from "../../types";


const initialState: LocalAddressState = {
  addresses: localAddressServices.getAddresses()
};

const localAddressReducer = (state = initialState, action: GenericAction): LocalAddressState => {

  switch (action.type) {
    case LOCAL_ADDRESS_ADD: {
      return {
        ...state,
        addresses: action.payload
      }
    }
    default:
      return state;
  }

}

export default localAddressReducer;
