import { GenericAction, NEIGHBORHOODS_GET, NeighborhoodsState } from '../../types';
import { getSuccessType } from '../promiseHelpers';


const initialState: NeighborhoodsState = {};

const neighborhoodsReducer = (state: NeighborhoodsState = initialState, action: GenericAction): NeighborhoodsState => {
    switch (action.type) {
        case getSuccessType(NEIGHBORHOODS_GET):
            return {
                ...state,
                neighborhoods: action.payload,
            }
        default:
            return state;
    }
}

export default neighborhoodsReducer;
