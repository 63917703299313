import { createSlice } from '@reduxjs/toolkit';

interface PreUpdateOrder {
  orderGuid: string;
}

interface PreUpdateOrderState {
  preUpdateOrders: PreUpdateOrder[];
}

const initialState: PreUpdateOrderState = {
  preUpdateOrders: [],
};

export const preUpdateOrderSlice = createSlice({
  name: 'resourceBlocked',
  initialState,
  reducers: {
    addPreUpdateOrder(state, { payload }: { payload: PreUpdateOrder }) {
      state.preUpdateOrders = [...state.preUpdateOrders, payload];
    },

    removePreUpdateOrder: (state, { payload: { orderGuid } }) => {
      state.preUpdateOrders = state.preUpdateOrders.filter(
        (order) => order.orderGuid !== orderGuid
      );
    },
  },
});

export default preUpdateOrderSlice.reducer;
export const { addPreUpdateOrder, removePreUpdateOrder } =
  preUpdateOrderSlice.actions;
