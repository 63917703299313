import { Product, ProductPaginatedData } from '@wls-solucoes/lets-eat-types';
import {
  GenericAction,
  PRODUCTS_GET,
  PRODUCTS_LOAD_MORE,
  PRODUCTS_REORDER,
  PRODUCTS_RESET,
  PRODUCT_ADD,
  PRODUCT_ADD_IMAGE,
  PRODUCT_CHANGE_VISIBILITY,
  PRODUCT_DEFINE_COVER,
  PRODUCT_DELETE,
  PRODUCT_DELETE_IMAGE,
  PRODUCT_UPDATE,
  ProductState,
} from '../../types';
import { getFailedType, getStartType, getSuccessType } from '../promiseHelpers';

const initialState: ProductState = {
  categoryGuid: '',
  loading: false,
  failed: false,
};

const productReducer = (
  state: ProductState = initialState,
  action: GenericAction
): ProductState => {
  switch (action.type) {
    case getStartType(PRODUCTS_GET):
      return { ...state, loading: true, failed: false };
    case getFailedType(PRODUCTS_GET):
      return { ...state, loading: false, failed: true };
    case getSuccessType(PRODUCTS_GET): {
      const resultArr = [...action.payload.product.result];

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          ...action.payload.product,
          result: resultArr,
        },
        categoryGuid: action.payload.categoryGuid,
        loading: false,
        failed: false,
      };
    }
    case getStartType(PRODUCTS_LOAD_MORE):
      return { ...state, loading: true, failed: false };
    case getFailedType(PRODUCTS_LOAD_MORE):
      return { ...state, loading: false, failed: true };
    case getSuccessType(PRODUCTS_LOAD_MORE): {
      const resultArr = [...action.payload.product.result];

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          ...action.payload.product,
          result: [...(state.product?.result ?? []), ...resultArr],
        },
        loading: false,
        failed: false,
      };
    }
    case getSuccessType(PRODUCT_ADD): {
      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          productHighlight: action.payload,
        },
      };
    }
    case getSuccessType(PRODUCT_DELETE):
      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          result: [
            ...(state.product?.result ?? []).filter(
              (x) => x.guid !== action.payload
            ),
          ],
          productHighlight: undefined,
        },
      };
    case getSuccessType(PRODUCT_CHANGE_VISIBILITY): {
      const newResult = [...(state.product as ProductPaginatedData).result];
      const index = newResult.findIndex((x) => x.guid === action.payload.guid);

      if (index !== -1) {
        newResult[index] = action.payload;
      }

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          result: newResult,
          productHighlight: action.payload.guid,
        },
      };
    }
    case getSuccessType(PRODUCT_UPDATE): {
      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          productHighlight: action.payload,
        },
      };
    }
    case getSuccessType(PRODUCT_ADD_IMAGE): {
      const newResult = [...(state.product as ProductPaginatedData).result];
      const index = newResult.findIndex((x) => x.guid === action.payload.guid);

      if (index !== -1) {
        newResult[index] = action.payload;
      }

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          result: newResult,
          productHighlight: action.payload.guid,
        },
      };
    }
    case getSuccessType(PRODUCT_DEFINE_COVER): {
      const newResult = [...(state.product as ProductPaginatedData).result];
      const index = newResult.findIndex((x) => x.guid === action.payload.guid);

      if (index !== -1) {
        newResult[index] = action.payload;
      }

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          result: newResult,
          productHighlight: action.payload.guid,
        },
      };
    }
    case getSuccessType(PRODUCT_DELETE_IMAGE): {
      const newResult = [...(state.product as ProductPaginatedData).result];
      const index = newResult.findIndex((x) => x.guid === action.payload.guid);

      if (index !== -1) {
        newResult[index] = action.payload;
      }

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          result: newResult,
          productHighlight: action.payload.guid,
        },
      };
    }
    case getSuccessType(PRODUCTS_RESET):
      return initialState;

    case getStartType(PRODUCTS_REORDER): {
      const product = (state.product?.result ?? []).find(
        (x) => x.guid === action.payload.itemGuid
      );

      const newArray = [...(state.product?.result ?? [])].filter(
        (x) => x.guid !== action.payload.itemGuid
      );

      newArray.splice(action.payload.priority, 0, product as Product);

      return {
        ...state,
        product: {
          ...(state.product as ProductPaginatedData),
          result: newArray,
        },
      };
    }
    default:
      return state;
  }
};

export default productReducer;
