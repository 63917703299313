/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { OpeningHoursSettingsState } from "../../types";
import { getOpeningHoursSettings, getOpeningHoursSettingsWithGuid } from "./actions";


const initialState: OpeningHoursSettingsState = {}

const openingHoursSettingsSlice = createSlice({
  name: 'openingHoursSettings',
  initialState,
  reducers: {},
  extraReducers: {
    [getOpeningHoursSettings.fulfilled.type]: (state, { payload }) => {
      state = {
        openingHoursSettings: payload,
        errorTime: undefined
      }
    },

    [getOpeningHoursSettings.rejected.type]: (state) => {
      state.errorTime = new Date();
    },

    [getOpeningHoursSettingsWithGuid.fulfilled.type]: (state, { payload }) => {
      state.openingHoursSettings = payload;
    },
  },
});

export default openingHoursSettingsSlice.reducer;