import { LagAndLog } from '../../modules/panel/pages/Preferences/components/DeliveryFee/components/MapDialog/MapDialog';
import { FetchPaginatedRequestParams } from '../../redux/types';
import { Address, AddressRequestForm } from './address';
import { City } from './city';
import { DocumentType } from './documentType';
import { PaginatedData } from './paginatedData';

export enum PersonContactType {
  email = 'email',
  phoneNumber = 'phoneNumber',
  whatsApp = 'whatsApp',
}

export enum PersonGender {
  male = 'male',
  female = 'female',
  ratherNotSay = 'ratherNotSay',
  custom = 'custom',
}

export enum PersonGenderToRefer {
  male = 'male',
  female = 'female',
  other = 'other',
}

export enum PersonSortBy {
  nameAscendant = 'nameAscendant',
  nameDescendant = 'nameDescendant',
  lastOrderDateAscendant = 'lastOrderDateAscendant',
  lastOrderDateDescendant = 'lastOrderDateDescendant',
  totalOrdersAscendant = 'totalOrdersAscendant',
  totalOrdersDescendant = 'totalOrdersDescendant',
}

export enum PersonFilterBy {
  all = 'all',
  recentlyAdded = 'recentlyAdded',
  active = 'active',
  inactive = 'inactive',
}

export interface PersonContactInformation {
  guid?: string;
  name?: string;
  value: string;
  type: PersonContactType;
  verified: boolean;
}

interface PersonBase {
  name: string;
  lastName?: string;
  document?: string;
  documentType: DocumentType;
  gender?: PersonGender;
  genderToRefer?: PersonGenderToRefer;
  genderName?: string;
  mainPhoneNumber?: string;
  contactsInformation: PersonContactInformation[];
}

export interface Person extends PersonBase {
  guid: string;
  readOnly: boolean;
  ordersCount: number;
  lastOrder?: string;
  addresses: Address[];
}

export interface PersonAddressRequestForm extends AddressRequestForm {
  city?: City;
  noNumber: boolean;
  coordinates?: LagAndLog;
}

export interface PersonRequestForm extends PersonBase {
  guid?: string;
  addresses: PersonAddressRequestForm[];
  ifoodGuid?: string;
}

export interface PersonPaginatedRequestParams
  extends FetchPaginatedRequestParams {
  sortBy?: PersonSortBy;
}

export interface PersonPaginatedData extends PaginatedData<Person> {
  sortBy: PersonSortBy;
  personFilterBy: PersonFilterBy;
}

export interface PersonReducerState {
  personPaginatedData: PersonPaginatedData;
  fetchDataStatus?: 'pending' | 'fulfilled' | 'rejected';
}
