/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PrinterStyleTypes } from '../../../shared/models/printerType';

const initialState: {
  isOpen: boolean;
  selectedIndex?: number;
  printerStyle?: PrinterStyleTypes;
} = {
  isOpen: false,
  selectedIndex: undefined,
};

export const ConfigDialogSlice = createSlice({
  name: 'ConfigDialog',
  initialState,
  reducers: {
    openConfigDialog: (state, { payload }) => {
      state.isOpen = true;
      state.selectedIndex = payload.index;
      state.printerStyle = payload.style;
    },
    closeConfigDialog: (state) => {
      state.isOpen = false;
      state.selectedIndex = undefined;
      state.printerStyle = undefined;
    },
  },
});

export default ConfigDialogSlice.reducer;
export const { closeConfigDialog, openConfigDialog } =
  ConfigDialogSlice.actions;
