import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { GenericAction } from '../types';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from './progressIndicator/progressIndicatorActions';

export const getStartType = (action: string): string => `${action}_START`;
export const getSuccessType = (action: string): string => `${action}_SUCCESS`;
export const getFailedType = (action: string): string => `${action}_FAILED`;

export const startPromise = (action: GenericAction): GenericAction => {
  return {
    ...action,
    type: getStartType(action.type),
  };
};

export const successPromise = (action: GenericAction): GenericAction => {
  return {
    ...action,
    type: getSuccessType(action.type),
  };
};

export const failedPromise = (action: GenericAction): GenericAction => {
  return {
    ...action,
    type: getFailedType(action.type),
  };
};

const removeIdFromProgressIndicator = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  id: string
): void => {
  dispatch(progressIndicatorRemove(id));
};

/**
 * Utilitário para resolver uma promise exibindo um indicador de progresso
 * e disparar actions sobre o progresso da mesma.
 *
 * ```
 * startPromise()    // Ao iniciar;
 * successPromise() // Ao finalizar sem erros, payload = successPayload,
 * failedPromise() // Ao finalizar com erros, payload = exception;
 *
 * ```
 * @param dispatch referencia do ThunkDispatch
 * utilizado para criar as actions de success e progressIndicator.
 * @param action action que será disparada ao finalizar a promise.
 * @param promise promise que deve ser resolvida.
 * @param successPayload callback para criar o payload da action de sucesso,
 * por padrão o payload será a solução completa da promise.
 * @param showProgressIndicator indica se o indicador de progresso deve ou não ser exibido, por padrão será exibido.
 *

 *
 * */
export const promiseWithProgressIndicator = async <T>(
  dispatch: ThunkDispatch<any, any, AnyAction>,
  action: string | undefined,
  promise: Promise<T>,
  successPayload: (promiseResult: T) => unknown = (r) => r,
  showProgressIndicator = true,
  startParams?: any
): Promise<void> => {
  /** Cria um id para execução dessa promise,
   *  o id será utilizado para indicar quantas promises ainda estão pendentes de ser resolvidas
   */
  const id = uuidv4();

  try {
    /**
     * Adiciona o id ao state do progress indicator.
     */
    if (showProgressIndicator) {
      dispatch(progressIndicatorAdd(id));
    }

    /**
     * Dispara a primeira action para indicar o inicio do processamento da promise.
     * */
    if (action !== undefined)
      dispatch(startPromise({ type: action, payload: startParams }));

    const result = await promise;

    /**
     * Obtém o payload utilizando o callback successPayload;
     * */
    const payload = successPayload(result);

    /**
     * Verifica se algum payload foi retornado pelo successPayload
     * */
    const hasPayload = payload !== undefined && payload !== null;

    /**
     * Monta a action de success com payload caso o mesmo exista.
     * */
    if (action !== undefined) {
      const success = hasPayload
        ? successPromise({ type: action, payload })
        : successPromise({ type: action });

      /**
       * Dispara action de success.
       * */
      dispatch(success);
    }

    /**
     * Remove o id do state do progress indicator
     */
    if (showProgressIndicator) {
      removeIdFromProgressIndicator(dispatch, id);
    }
  } catch (e) {
    /**
     * Dispara uma action indicando que a promise falhou, passando como payload o erro ocorrido.
     * */
    if (action !== undefined)
      dispatch(failedPromise({ type: action, payload: e }));

    /**
     * Remove o id em caso de falhas na execução da promise.
     * */
    if (showProgressIndicator) {
      removeIdFromProgressIndicator(dispatch, id);
    }

    if (e !== undefined && (e as any)?.response?.status !== 401) {
      throw e;
    }
  }
};
