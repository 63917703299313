import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeGuide,
  nextStepGuide,
  previusStepGuide,
} from '../../../redux/reducers/guide/reducer';
import { setCompletedSteps } from '../../../redux/reducers/user/userActions';
import { RootState } from '../../../redux/types';
import { Step } from '../../models/loginStep';
import Indication from './components/Indication';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    maxWidth: 442,
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '26px',
    marginTop: 24,
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    margin: '16px 0',
    color: theme.palette.grey[700],
  },
  closeButton: {
    zIndex: 2,
    backgroundColor: 'white',
    position: 'absolute',
    top: 24,
    right: 24,
    '&:hover': { backgroundColor: 'white' },
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
  },
}));

type GuideDialogProps = {};

const GuideDialog: React.FC<GuideDialogProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [imageLoad, setImageLoad] = useState(false);

  const { isOpen, guide } = useSelector(
    (state: RootState) => state.guideReducer
  );

  const selectedStep = guide.steps[guide.currentStep];
  const isLastStep = guide.currentStep >= guide.steps.length - 1;
  const isFirstStep = guide.currentStep <= 0;

  const imageOnLoad = (): void => {
    setImageLoad(true);
  };

  const onClose = (): void => {
    dispatch(closeGuide());
  };

  const nextStep = (): void => {
    if (isLastStep) {
      dispatch(setCompletedSteps(Step.qztrayGuide));
      dispatch(closeGuide());
      return;
    }
    setImageLoad(false);
    dispatch(nextStepGuide());
  };

  const previusStep = (): void => {
    if (isFirstStep) return;
    setImageLoad(false);
    dispatch(previusStepGuide());
  };

  const closeDialog = (): void => {
    dispatch(closeGuide());
  };

  if (selectedStep === undefined) {
    return <></>;
  }

  return (
    <Dialog
      open={isOpen}
      onBackdropClick={onClose}
      onClose={onClose}
      classes={{
        paper: classes.root,
      }}
    >
      <Box style={{ padding: 0, display: 'flex' }}>
        <IconButton
          size="small"
          onClick={onClose}
          classes={{
            root: classes.closeButton,
          }}
        >
          <Close />
        </IconButton>
        <img
          src={selectedStep.image}
          alt={selectedStep.title}
          style={{
            width: '100%',
            height: '100%',
            opacity: imageLoad ? 1 : 0,
            transition: 'opacity 0.3s',
          }}
          onLoad={imageOnLoad}
        />
      </Box>

      <DialogContent style={{ padding: '16px 24px' }}>
        <Indication
          length={guide.steps.length}
          currentStep={guide.currentStep}
        />
        <Typography className={classes.title}>{selectedStep.title}</Typography>
        <Typography className={classes.description}>
          {selectedStep.description}
        </Typography>
        <Typography>{selectedStep.content}</Typography>
      </DialogContent>

      <DialogActions style={{ padding: 24, justifyContent: 'space-between' }}>
        <Button color="primary" onClick={closeDialog} variant="text">
          pular
        </Button>
        <Grid
          container
          style={{ display: 'flex', justifyContent: 'end', gap: 16 }}
        >
          <Grid item>
            <Button
              color="primary"
              onClick={previusStep}
              disabled={isFirstStep}
              variant="outlined"
            >
              voltar
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={nextStep} variant="contained">
              {isLastStep ? 'entendi' : 'próximo'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GuideDialog;
