import {
  AiquefomeConfiguration,
  OpenCloseBehavior,
} from '@wls-solucoes/lets-eat-types';
import Api from './api';

const base = '/api/integrations/aiquefome';

async function getConfiguration(): Promise<AiquefomeConfiguration> {
  return Api.get(base, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function addUser(
  username: string,
  password: string
): Promise<AiquefomeConfiguration> {
  return Api.post(
    `${base}/user`,
    { username, password },
    {
      headers: {
        'x-api-version': 3,
        authHeader: true,
      },
    }
  ).then((res) => res.data);
}

async function removeUser(guid: string): Promise<AiquefomeConfiguration> {
  return Api.delete(`${base}/user/${guid}`, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
      successMessage: 'Loja removida com sucesso!',
    },
  }).then((res) => res.data);
}

async function openStore(storeId: number): Promise<void> {
  return Api.post(`${base}/store/${storeId}/open`, undefined, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then();
}

async function closeStore(storeId: number): Promise<void> {
  return Api.post(`${base}/store/${storeId}/close`, undefined, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then();
}

async function changeOpenCloseBehaviour(
  value: OpenCloseBehavior
): Promise<void> {
  return Api.patch(
    `${base}/open-close-behaviour`,
    { value },
    {
      headers: {
        'x-api-version': 3,
        authHeader: true,
      },
    }
  ).then();
}

export const aiqfomeService = {
  getConfiguration,
  addUser,
  removeUser,
  openStore,
  closeStore,
  changeOpenCloseBehaviour,
};
