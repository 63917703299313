import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderSettingsService } from '../../../shared/services/api';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchOrderSettings = createAsyncThunk(
  'orderSettings/fetchOrderSettings',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return orderSettingsService
      .getSettings()
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);
