import { appHistory } from '../../helpers/utils/appHistory';
import { handleUserTokenExpired } from '../../redux/reducers/user/userActions';
import { store } from '../../redux/store';
import { LoggedUser } from '../models/loggedUser';

export const key = 'pikachu';
export const firstLoginEverKey = 'mrmime';

function getAuth(): LoggedUser {
  const auth = localStorage.getItem(key);
  if (auth === null) {
    store.dispatch(handleUserTokenExpired());
    appHistory.push('/login');
    throw new Error('token não encontrado');
  }

  const parsedAuth = JSON.parse(auth) as LoggedUser;

  if (parsedAuth.establishment?.path === undefined) {
    store.dispatch(handleUserTokenExpired());
  }

  return parsedAuth;
}

function setAuth<T>(auth: T): void {
  localStorage.setItem(key, JSON.stringify(auth));

  const hasLoggedBefore = localStorage.getItem(firstLoginEverKey) !== null;
  if (!hasLoggedBefore) {
    localStorage.setItem(firstLoginEverKey, 'true');
  }
}

function setAccessToken(accessToken: string): void {
  const authStorage = JSON.parse(localStorage.getItem(key) ?? '{}');
  localStorage.setItem(
    key,
    JSON.stringify({
      ...authStorage,
      accessToken,
    })
  );

  const hasLoggedBefore = localStorage.getItem(firstLoginEverKey) !== null;
  if (!hasLoggedBefore) {
    localStorage.setItem(firstLoginEverKey, 'true');
  }
}

function setRefreshToken(refreshToken: string): void {
  const authStorage = JSON.parse(localStorage.getItem(key) ?? '{}');
  localStorage.setItem(
    key,
    JSON.stringify({
      ...authStorage,
      refreshToken,
    })
  );
}

function removeAuth(): void {
  localStorage.removeItem(key);
}

export const AuthService = {
  getAuth,
  setAuth,
  removeAuth,
  setAccessToken,
  setRefreshToken,
};
