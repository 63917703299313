import { createSlice } from '@reduxjs/toolkit';
import { TimeZone } from '@wls-solucoes/lets-eat-types';
import { fetchTimeZones } from './actions';

interface TimeZoneState {
  timeZones?: TimeZone[];
}

const initialState: TimeZoneState = {};

const timeZoneSlice = createSlice({
  name: 'timeZone',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTimeZones.fulfilled.type]: (state, { payload }) => {
      state.timeZones = payload;
    },
  },
});

export default timeZoneSlice.reducer;
