import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../../shared/services/api';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const planGet = createAsyncThunk(
  'plan/get',
  async (p, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return Api.get('api/Plan', {
      headers: {
        authHeader: true,
      },
    })
      .then(({ data }) => data)
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const planGetAll = createAsyncThunk(
  'plan/getAll',
  async (p, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return Api.get('api/Plan/all')
      .then(({ data }) => data)
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);
