/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { OrderTimingType } from '@wls-solucoes/lets-eat-types';
import { PanelOrderCheckout } from '../../../shared/models/order/order';
import { OrderStatus } from '../../../shared/models/order/status';
import { PaymentType } from '../../../shared/models/paymentType';

const initialState: PanelOrderCheckout = {
  status: {
    status: OrderStatus.accepted,
  },
  items: [],
  payment: {
    paymentType: PaymentType.cash,
  },
  timing: {
    orderTimingType: OrderTimingType.immediate,
  },
};

export const panelOrderSlice = createSlice({
  name: 'panelCatalog',
  initialState,
  reducers: {
    clearOrder() {
      return { ...initialState };
    },

    setClient(state, { payload }) {
      state.clientGuid = payload;
    },

    removeClient(state) {
      state.clientGuid = undefined;
    },

    setSelectedClient(state, { payload }) {
      state.selectedClient = payload;
    },

    removeSelectedClient(state) {
      state.selectedClient = undefined;
    },

    changeStatus(state, { payload }) {
      state.status.status = payload;
    },

    addItem(state, { payload }) {
      state.items?.push({ ...payload });
    },

    removeItem(state, { payload }) {
      state.items = state.items?.filter((_, index) => index !== payload);
    },

    removeItemsByGuid(state, { payload }) {
      state.items = state.items?.filter((item) => item.guid !== payload);
    },

    editItem(state, { payload }) {
      if (state.items !== undefined) {
        const newItemsArray = [
          ...state.items.slice(0, payload.index),
          { ...payload.item },
          ...state.items.slice(payload.index + 1),
        ];

        state.items = [...newItemsArray];
      }
    },

    selectItem(state, { payload }) {
      if (state.items !== undefined) {
        const foundItem = state.items.find((i, index) => index === payload);

        if (foundItem !== undefined) {
          const newItemsArray = [
            ...state.items.slice(0, payload),
            { ...foundItem, isSelected: true },
            ...state.items.slice(payload + 1),
          ];

          state.items = [...newItemsArray];
        }
      }
    },

    unselectItem(state, { payload }) {
      if (state.items !== undefined) {
        const foundItem = state.items.find((i, index) => index === payload);

        if (foundItem !== undefined) {
          const newItemsArray = [
            ...state.items.slice(0, payload),
            { ...foundItem, isSelected: false },
            ...state.items.slice(payload + 1),
          ];

          state.items = [...newItemsArray];
        }
      }
    },

    selectAllItems(state) {
      if (state.items !== undefined) {
        state.items = state.items.map((item) => {
          return {
            ...item,
            isSelected: true,
          };
        });
      }
    },

    clearSelection(state) {
      if (state.items !== undefined) {
        state.items = state.items.map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
      }
    },

    removeSelectedItems(state) {
      if (
        state.items !== undefined &&
        state.items.some((item) => item.isSelected === true)
      ) {
        state.items = state.items.filter((item) => item.isSelected === false);
      }
    },

    setDiscountCode(state, { payload }) {
      state.discountCode = payload;
    },

    addDiscount(state, { payload }) {
      state.discounts?.push(payload);
    },

    removeDiscount(state, { payload }) {
      state.discounts?.filter((x) => x !== payload);
    },

    setPaymentMethod(state, { payload }) {
      state.payment = payload;
    },

    addPaymentMethod(state, { payload }) {
      if (state.payments !== undefined) {
        state.payments.push(payload);
      } else {
        state.payments = [{ ...payload }];
      }
    },

    editPaymentMethod(state, { payload }) {
      if (state.payments !== undefined) {
        const newItemsArray = [
          ...state.payments.slice(0, payload.index),
          { ...payload.payment },
          ...state.payments.slice(payload.index + 1),
        ];

        state.payments = [...newItemsArray];
      }
    },

    removePaymentMethod(state, { payload }) {
      state.payments = state.payments?.filter((x, index) => index !== payload);
    },

    setDelivery(state, { payload }) {
      state.delivery = payload;
    },

    resetDelivery(state) {
      state.delivery = undefined;
      state.timing = {
        orderTimingType: OrderTimingType.immediate,
        deliveryDateTimeEnd: undefined,
        deliveryDateTimeStart: undefined,
      };
    },

    setTiming(state, { payload }) {
      state.timing = payload;
    },

    resetTiming(state) {
      state.timing = {
        orderTimingType: OrderTimingType.immediate,
        deliveryDateTimeEnd: undefined,
        deliveryDateTimeStart: undefined,
      };
    },
  },
});

export const {
  clearOrder,
  setClient,
  removeClient,
  setSelectedClient,
  removeSelectedClient,
  addDiscount,
  removeDiscount,
  changeStatus,
  addItem,
  removeItem,
  removeItemsByGuid,
  editItem,
  selectItem,
  unselectItem,
  selectAllItems,
  clearSelection,
  removeSelectedItems,
  addPaymentMethod,
  editPaymentMethod,
  removePaymentMethod,
  setDiscountCode,
  setPaymentMethod,
  setDelivery,
  resetDelivery,
  setTiming,
  resetTiming,
} = panelOrderSlice.actions;

export default panelOrderSlice.reducer;
