/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../../../shared/components/ResourceBlockedTemplate/ResourceBlockedTemplateIndicator/ResourceBlockedTemplateIndicator';

interface ResourceBlockedState {
  isOpen: boolean;
  feature?: Features;
}

const initialState: ResourceBlockedState = {
  isOpen: false,
};

export const resourceBlockedSlice = createSlice({
  name: 'resourceBlocked',
  initialState,
  reducers: {
    openResourceBlockedDialog(state, { payload }) {
      state.isOpen = true;
      state.feature = payload;
    },

    closeResourceBlockedDialog(state) {
      state.isOpen = false;
      state.feature = undefined;
    },
  },
});

export default resourceBlockedSlice.reducer;
export const { openResourceBlockedDialog, closeResourceBlockedDialog } =
  resourceBlockedSlice.actions;
