import { createBrowserHistory } from 'history';
import { analytics } from "../../shared/services/analytics";

const history = createBrowserHistory();

history?.listen((location) => {
  const page = location.pathname || window.location.pathname;
  analytics.logPageView(page);
});

export const appHistory = history;
