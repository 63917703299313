
import { GenericAction, PostState, POST_GET } from '../../types';
import { getSuccessType } from '../promiseHelpers';


const initialState: PostState = {};

const postReducer = (state: PostState = initialState, action: GenericAction): PostState => {
    switch (action.type) {
        case getSuccessType(POST_GET):
            return {
                ...state,
                posts: action.payload
            }
        default:
            return state;
    }
}
 
export default postReducer;
