import { createSlice } from '@reduxjs/toolkit';
import { LocalOrderSettingsState } from '../../types';
import notifSound from '../../../modules/panel/pages/Orders/assets/notification_2.mp3';

const initialState: LocalOrderSettingsState = {
  notificationVolume: 0.5,
  showCategoryNameOnOrder: true,
  notificationSound: notifSound,
  confirmationToCancelOrder: true,
  confirmationToFinishOrder: false,
};

export const localOrderSettingsSlice = createSlice({
  name: 'localOrderSettings',
  initialState,
  reducers: {
    setNotificationVolume(state, { payload }) {
      state.notificationVolume = payload;
    },
    setShowCategoryNameOnOrder(state, { payload }) {
      state.showCategoryNameOnOrder = payload;
    },
    setNotificationSound(state, { payload }) {
      state.notificationSound = payload;
    },
    setConfirmationToCancelOrder(state, { payload }) {
      state.confirmationToCancelOrder = payload;
    },
    setConfirmationToFinishOrder(state, { payload }) {
      state.confirmationToFinishOrder = payload;
    },
  },
});

export default localOrderSettingsSlice.reducer;
export const {
  setNotificationVolume,
  setShowCategoryNameOnOrder,
  setNotificationSound,
  setConfirmationToCancelOrder,
  setConfirmationToFinishOrder,
} = localOrderSettingsSlice.actions;
