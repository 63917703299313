/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GuideStep } from '../../../shared/models/guide';
import { GuideState } from '../../types';

const initialState: GuideState = {
  guide: {
    steps: [],
    currentStep: 0,
  },
  isOpen: false,
};

export const guidelice = createSlice({
  name: 'guide',
  initialState,
  reducers: {
    closeGuide(state) {
      state.guide.steps = [];
      state.guide.currentStep = 0;
      state.isOpen = false;
    },
    openGuide(state, { payload }: PayloadAction<GuideStep[]>) {
      state.guide.steps = payload;
      state.guide.currentStep = 0;
      state.isOpen = true;
    },
    nextStepGuide(state) {
      state.guide.currentStep += 1;
    },
    previusStepGuide(state) {
      state.guide.currentStep -= 1;
    },
  },
});

export default guidelice.reducer;
export const { closeGuide, openGuide, nextStepGuide, previusStepGuide } =
  guidelice.actions;
