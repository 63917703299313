import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import componentLoader from './helpers/misc/componentLoader';
import { appHistory } from './helpers/utils/appHistory';
import { goToRegister } from './helpers/utils/externalLinks';
import AuthenticatePage from './modules/Authenticate';
import RouteProgressIndicator from './shared/components/RouteProgressIndicator';

const MainRouter: React.FC = () => {
  const Landing = lazy(() => componentLoader(() => import('./Landing')));
  const LoginModule = lazy(() =>
    componentLoader(() => import('./modules/login/index'))
  );
  const AuthenticateWithToken = lazy(() =>
    componentLoader(() => import('./modules/AuthenticateWithToken'))
  );
  const PanelModule = lazy(() =>
    componentLoader(() => import('./modules/panel'))
  );
  const PublicModule = lazy(() =>
    componentLoader(() => import('./modules/public/index'))
  );
  const NotFoundPage = lazy(() =>
    componentLoader(() => import('./shared/components/NotFoundPage'))
  );
  const newPasswordModule = lazy(() =>
    componentLoader(() => import('./modules/newPassword/pages'))
  );
  const DisconnectedModule = lazy(() =>
    componentLoader(() => import('./modules/Disconnected/pages'))
  );

  return (
    <Router history={appHistory}>
      <Suspense fallback={<RouteProgressIndicator />}>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/authenticate" component={AuthenticatePage} />
          <Route path="/login" component={LoginModule} />
          <Route
            path="/authenticateWithToken"
            component={AuthenticateWithToken}
          />
          <Redirect exact from="/sign-up" to="/m/register" />
          <Route
            path="/m/register"
            render={() => {
              goToRegister();
              return <></>;
            }}
          />
          <Route path="/m/reset-password" component={newPasswordModule} />
          <Route path="/panel" component={PanelModule} />
          <Route path="/404" component={NotFoundPage} />
          <Route path="/ex/planOffer" component={DisconnectedModule} />
          <Route path="/:establishmentPath" component={PublicModule} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default MainRouter;
