import { Step } from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import Api from './api';

function sendStep(step: Step): Promise<AxiosResponse> {
  return Api.post('/api/User/steps', undefined, {
    params: {
      step,
    },
    headers: {
      authHeader: true,
    },
  });
}

export const loginStepServices = {
  sendStep,
};
