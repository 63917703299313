import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CoreThemeWrapper } from '@wls-solucoes/lets-eat-core';
import { OrdersThemeWrapper } from '@wls-solucoes/lets-eat-orders';
import { ReportsThemeWrapper } from '@wls-solucoes/lets-eat-reports';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { RootState } from '../../../redux/types';
import CustomScrollBar from './Components/CustomScrollBar/CustomScrollBar';
import { useExternalData } from '../../../helpers/hooks/useExternalData';

export interface AppThemeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: ReactElement<any, any>;
}

const AppTheme: React.FC<AppThemeProps> = (props) => {
  const theme = useSelector((state: RootState) => state.themeReducer);
  const { theme: externalTheme } = useExternalData();

  const { breakpoints } = useTheme();
  const iNotsm = useMediaQuery(breakpoints.up('sm'));

  const { children } = props;
  const { colors, mode: localMode } = theme;

  const themeMode = externalTheme || localMode;

  const appTheme = createTheme({
    typography: {
      fontFamily: ['Nunito', 'Roboto', 'sans-serif'].join(','),
      body1: {
        fontWeight: 500,
      },
      h1: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h2: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h3: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h4: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h5: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
      h6: {
        fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
            fontWeight: 700,
          },
        },
      },
    },
    palette: {
      mode: themeMode,
      primary: {
        main: colors.primaryColor ?? '#3DA865',
        light: '#ECF6F0',
        dark: '#37975B',
      },
      text: {
        primary: themeMode === 'light' ? '#000' : '#F3F3F3',
        secondary: themeMode === 'light' ? '#313533' : '#B0B5B2',
      },
      secondary: {
        main: colors.secondaryColor ?? '#0366DE',
      },
      grey:
        themeMode === 'light'
          ? {
              50: '#F3F3F3',
              100: '#E4E6E5',
              200: '#CACECC',
              300: '#B0B5B2',
              400: '#969C98',
              500: '#7C837F',
              600: '#636965',
              700: '#4A4F4C',
              800: '#313533',
              900: '#191A19',
            }
          : {
              50: '#F3F3F3',
              100: '#3c403d',
              200: '#636965',
              300: '#ffffff',
              400: '#CACECC',
              500: '#7C837F',
              600: '#969C98',
              700: '#B0B5B2',
              800: '#313533',
              900: '#191A19',
            },
    },
  });

  return (
    <div data-theme={themeMode}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <CoreThemeWrapper theme={appTheme as any}>
            <OrdersThemeWrapper theme={appTheme as any}>
              <ReportsThemeWrapper theme={appTheme}>
                <ToastContainer
                  limit={3}
                  position="bottom-center"
                  theme={themeMode === 'dark' ? 'dark' : undefined}
                />
                {iNotsm && <CustomScrollBar />}
                {children}
              </ReportsThemeWrapper>
            </OrdersThemeWrapper>
          </CoreThemeWrapper>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default AppTheme;
