/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { UIControllerState } from '../../types';

const initialState: UIControllerState = {
  forceSideBarRetraction: false,
  hideAppBar: false,
};

export const uiControllerSlice = createSlice({
  name: 'uiController',
  initialState,
  reducers: {
    setForceSideBarRetraction(state, { payload }) {
      state.forceSideBarRetraction = payload;
    },
    setHideAppBar(state, { payload }) {
      state.hideAppBar = payload;
    },
  },
});

export default uiControllerSlice.reducer;
export const { setForceSideBarRetraction, setHideAppBar } =
  uiControllerSlice.actions;
