 import { GenericAction, CardFlagState, CARDFLAG_GET} from '../../types';
import { getSuccessType } from '../promiseHelpers';


const initialState: CardFlagState = {};

const cardFlagReducer = (state: CardFlagState = initialState, action: GenericAction): CardFlagState => {
    switch (action.type) {
        case getSuccessType(CARDFLAG_GET):
            return {
                ...state,
                cardFlags: action.payload
            }
        default:
            return state;
    }
}

export default cardFlagReducer;
