import { ChatBot } from '@wls-solucoes/lets-eat-types';

import Api from './api';

const ApiUrl = `/api/integrations/chat-bot`;

const getChatBotStatus = async (): Promise<Partial<ChatBot>> => {
  return Api.get(ApiUrl, {
    headers: {
      authHeader: true,
      'x-api-version': 3,
    },
  }).then((res) => res.data);
};

export const chatBotService = {
  getChatBotStatus,
};
