import { createSlice } from '@reduxjs/toolkit';
import { PrintGroupsState } from '../../types';
import { fetchAvailableItems, fetchPrintGroupsSettings } from './actions';

const initialState: PrintGroupsState = {
  groupsConfig: [],
};

export const printGroupsSlice = createSlice({
  name: 'printGroups',
  initialState,
  reducers: {
    updatePrintGroupsSettings(state, { payload }) {
      state.printGroupsSettings = payload;
    },
    updateAvailableItems(state, { payload }) {
      state.filteredPrintGroupAvailableCategories = payload;
    },

    updateGroupsConfig(state, { payload }) {
      state.groupsConfig = payload;
    },
  },
  extraReducers: {
    [fetchPrintGroupsSettings.fulfilled.type]: (state, { payload }) => {
      state.printGroupsSettings = payload;
    },
    [fetchAvailableItems.fulfilled.type]: (state, { payload }) => {
      state.printGroupAvailableCategories = payload;
      state.filteredPrintGroupAvailableCategories = payload;
    },
  },
});

export default printGroupsSlice.reducer;
export const {
  updatePrintGroupsSettings,
  updateAvailableItems,
  updateGroupsConfig,
} = printGroupsSlice.actions;
