import { createSlice } from '@reduxjs/toolkit';
import { OrderSettingsState } from '../../types';
import { fetchOrderSettings } from './actions';

const initialState: OrderSettingsState = {};

export const orderSettingsSlice = createSlice({
  name: 'orderSettings',
  initialState,
  reducers: {
    setOrderSettings: (state, { payload }) => {
      state.orderSettings = payload;
    },
  },
  extraReducers: {
    [fetchOrderSettings.fulfilled.type]: (state, { payload }) => {
      state.orderSettings = payload;
    },
  },
});

export default orderSettingsSlice.reducer;
export const { setOrderSettings } = orderSettingsSlice.actions;
