import { env } from "../../env";

export default function componentLoader(lazyComponent: any, attemptsLeft = 5): any {
  return new Promise((resolve, reject) => {
    lazyComponent().then(resolve).catch((error: any) => {
      setTimeout(() => {
        if (attemptsLeft === 1) {
          reject(error);
          return;
        }
        componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
      }, 1500);
    }).finally(() => {
      if (attemptsLeft !== 5 && !env.isProd) {
        alert(`lazy import retry (${attemptsLeft})`)
      }
    });
  });
}
