import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  UserNotificationPaginatedData,
  UserNotificationPaginatedRequestParams,
} from '@wls-solucoes/lets-eat-types';
import { userNotificationService } from '../../../shared/services/api';
import { store } from '../../store';
import { NotificationsState } from '../../types';

export const fetchNotifications = createAsyncThunk(
  'notifications/fetch',
  async (params: UserNotificationPaginatedRequestParams) => {
    const { notificationsReducer } = store.getState();
    const { userNotificationsPaginatedData: currentData } =
      notificationsReducer as NotificationsState;

    const currentPage = currentData?.currentPage ?? 1;
    const pageSize = currentData?.pageSize ?? 10;

    return userNotificationService.getPaginated({
      page: params?.page ?? currentPage,
      pageSize: params?.pageSize ?? pageSize,
      query: params?.query,
      status: params?.status,
    });
  }
);

export const markAsRead = createAsyncThunk(
  'notifications/markAsRead',
  async (guidList: string[]) => {
    return userNotificationService.markAsRead(guidList).then(() => {
      const { notificationsReducer } = store.getState();
      const { status } =
        notificationsReducer.userNotificationsPaginatedData as UserNotificationPaginatedData;

      store.dispatch(fetchNotifications({ status }));
    });
  }
);

export const markAllAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async () => {
    return userNotificationService.markAllAsRead().then(() => {
      const { notificationsReducer } = store.getState();
      const { status } =
        notificationsReducer.userNotificationsPaginatedData as UserNotificationPaginatedData;

      store.dispatch(fetchNotifications({ status }));
    });
  }
);
