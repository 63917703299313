/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DesktopPrinterModel } from '../../../shared/models/printerType';

const initialState: DesktopPrinterModel = {
  serverPrintUrl: 'http://localhost:8844',
  serverPrintConfigs: {
    customFont: 'default',
    printWidth: 80,
    fontSize: 'medium',
    printModel: 'full',
    printDuplicatedModel: 'full',
    automaticPrintWhenFinishOrder: false,
    printTwoCopies: false,
    marginRight: 0,
    marginLeft: 0,
  },
  serverPrintModel: {
    fullModel: {
      showCategory: true,
      showDescription: false,
      showLogo: true,
      showCNPJ: true,
      showClientCPF: true,
      showPaymentInfo: true,
      showOrderOrigin: false,
      showAddress: true,
      showPrintGroup: true,
      showTelephone: true,
      showItemsDivider: false,
      showCatalogQRCode: true,
      showEstablishmentAddress: true,
      useExternalInfo: true,
    },
    summaryModel: {
      showCategory: true,
      showDescription: false,
      showValues: false,
      showTotal: true,
      showClientCPF: false,
      showOrderOrigin: false,
      showName: true,
      showAddress: true,
      showTelephone: true,
      showPrintGroup: true,
      showItemsDivider: false,
    },
  },
};

export const DesktopPrinterConfigSlice = createSlice({
  name: 'DesktopPrinterConfig',
  initialState,
  reducers: {
    setServerPrinterConfig: (state, { payload }) => {
      state.serverPrintConfigs = payload;
    },
    setServerPrintModel: (state, { payload }) => {
      state.serverPrintModel = payload;
    },
  },
});

export default DesktopPrinterConfigSlice.reducer;
export const { setServerPrinterConfig, setServerPrintModel } =
  DesktopPrinterConfigSlice.actions;
