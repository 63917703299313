import { createAsyncThunk } from '@reduxjs/toolkit';
import { iFoodService } from '../../../shared/services/iFood';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchIFoodConfiguration = createAsyncThunk(
  'iFood/fetchIFoodConfiguration',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return iFoodService.getConfiguration().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
