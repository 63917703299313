/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CatalogGuideState } from "../../types";

const initialState: CatalogGuideState = {
  firstTimeBuying: true
};

export const catalogGuideSlice = createSlice({
  name: 'CatalogGuide',
  initialState,
  reducers: {
    resetCatalogGuide() {
      return { ...initialState };
    },

    setFirstTimeBuying(state, { payload }) {
      state.firstTimeBuying = payload;
    }
  },
})

export default catalogGuideSlice.reducer;
export const {resetCatalogGuide, setFirstTimeBuying} = catalogGuideSlice.actions