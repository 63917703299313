import { createAsyncThunk } from '@reduxjs/toolkit';
import { indicatorsService } from '../../../shared/services/indicators';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const getTodayIndicators = createAsyncThunk(
  'indicators/getTodayIndicators',
  async (params, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));
    return indicatorsService.getTodayIndicators().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const getYesterdayIndicators = createAsyncThunk(
  'indicators/getYesterdayIndicators',
  async (params, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));
    return indicatorsService.getYesterdayIndicators().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const getLastSevenDaysIndicators = createAsyncThunk(
  'indicators/getLastSevenDaysIndicators',
  async (params, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));
    return indicatorsService.getLastSevenDaysIndicators().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const getLastThirtyDaysIndicators = createAsyncThunk(
  'indicators/getLastThirtyDaysIndicators',
  async (params, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));
    return indicatorsService.getLastThirtyDaysIndicators().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const getThisMonthIndicators = createAsyncThunk(
  'indicators/getThisMonthIndicators',
  async (params, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));
    return indicatorsService.getThisMonthIndicators().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
