/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PlanState } from '../../types';
import { planGet, planGetAll } from './actions';

const initialState: PlanState = {};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlanInfo(state, { payload }) {
      return {
        ...state,
        plan: payload,
      };
    },

    planOrderLoad(state, { payload }) {
      return {
        ...state,
        planOrder: payload,
      };
    },

    planOrderClear(state) {
      return {
        ...state,
        planOrder: undefined,
      };
    },
  },
  extraReducers: {
    [planGet.fulfilled.type]: (state, { payload }) => {
      state.plan = payload;
    },
    [planGetAll.fulfilled.type]: (state, { payload }) => {
      state.plansOptions = payload;
    },
  },
});

export default planSlice.reducer;
export const { setPlanInfo, planOrderLoad, planOrderClear } = planSlice.actions;
