export const GENERATE_LEAD = "generateLead";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const NEW_ITEM = "newItem";
export const NEW_OPTIONAL = "newOptional";
export const NEW_ORDER = "newOrder";
export const NEW_CATEGORY = "newCategory";
export const NEW_USER = "newUser";
export const EDIT_ITEM = "editItem";
export const EDIT_OPTIONAL = "editOptional";
export const EDIT_CATEGORY = "editCategory";
export const ADD_TO_CART = "addToCart";
export const REMOVE_FROM_CART = "removeFromCart";
export const VIEW_ITEM = "viewItem";
export const REMOVE_ITEM = "removeItem";
export const REMOVE_USER = "removeUser";
export const REMOVE_OPTIONAL = 'removeOptional';
export const CHANGE_CATEGORY_VISIBILITY = "changeCategoryVisibility";
export const CHANGE_ITEM_VISIBILITY = "changeItemVisibility";
export const CHANGE_OPTIONAL_VISIBILITY = "changeOptionalVisibility";
export const OPEN_MAPS_URL = "openMapsUrl";
export const SIGNED_NEW_PLAN = "signedNewPlan";
export const CLICK_PROMOTIONAL_BANNER_1 = 'promotionalBannerOne';
export const CLICK_SHOW_BANKSLIP = 'showBankslip';
export const CLICK_SHOW_BANKSLIP_PDF = 'showBankslipPdf';
export const CLICK_COPY_BANKSLIP_CODEBAR = 'copyBankslipCodebar';
