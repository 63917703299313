import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app';
import { env } from './env';
import { appHistory } from './helpers/utils/appHistory';
import './index.css';
import { persistor, store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import RouteProgressIndicator from './shared/components/RouteProgressIndicator';

if (env.isProd) {
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: env.REACT_APP_SENTRY_ENVIRONMENT,
    allowUrls: ['compraqui.app'],
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(appHistory),
      }),
      new SentryRRWeb(),
    ],

    beforeSend(event, hint) {
      if (hint?.originalException === 'Timeout') return null;
      return event;
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
  });
} else {
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    allowUrls: ['dbg.compraqui.app', 'localhost:3001'],
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(appHistory),
      }),
      new SentryRRWeb(),
    ],

    beforeSend(event, hint) {
      if (hint?.originalException === 'Timeout') {
        console.log('intercepted Timeout error');
        return null;
      }
      return event;
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
  });
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={<RouteProgressIndicator />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
