import axios, { InternalAxiosRequestConfig } from 'axios';
import { appHistory } from '../../helpers/utils/appHistory';
import { AuthService } from '../services/authService';
import { AUTHENTICATION_REQUIRED_HEADER_NAME as authHeader } from './headers';

const onAuthError = (): void => {
  appHistory.push('/login');
};

const AuthInterceptor = async (
  config: InternalAxiosRequestConfig<any>
): Promise<InternalAxiosRequestConfig<any>> => {
  const isAuthRequired = config.headers?.authHeader !== undefined;

  if (isAuthRequired) {
    try {
      const { accessToken } = AuthService.getAuth();

      if (accessToken === '') {
        onAuthError();
      }
      const configWithAuth = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      } as any;
      delete configWithAuth.headers[authHeader];
      return configWithAuth;
    } catch (err: any) {
      onAuthError();
      throw new axios.Cancel(err.message);
    }
  }
  return config;
};

export default AuthInterceptor;
