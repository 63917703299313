import { createSlice } from '@reduxjs/toolkit';
import { CatalogCategory } from '../../../shared/models/category';
import { fetchPanelCatalog } from './actions';

const initialState: CatalogCategory[] = [];

export const panelCatalogSlice = createSlice({
  name: 'panelCatalog',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPanelCatalog.fulfilled.type]: (state, { payload }) => {
      return payload.data;
    },
  },
});

export default panelCatalogSlice.reducer;
