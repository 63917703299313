import { createSlice } from '@reduxjs/toolkit';
import {
  CompletedLoyaltyPlanSortBy,
  PersonLoyaltyPlanSortBy,
} from '@wls-solucoes/lets-eat-types';
import { LoyaltyPlanState } from '../../types';
import {
  fetchLoyaltyPlan,
  fetchLoyaltyPlanStatistics,
  fetchPaginatedCompletedLoyaltyPlans,
  fetchPaginatedLoyaltyPlanPersons,
} from './actions';

const initialState: LoyaltyPlanState = {
  personsSearchTerm: '',
  completedPlansSearchTerm: '',
  paginatedLoyaltyPlanPersons: {
    currentPage: 1,
    pageSize: 10,
    result: [],
    sortBy: PersonLoyaltyPlanSortBy.nameAsc,
    totalItems: 0,
    totalPages: 1,
    query: '',
  },
  paginatedCompletedLoyaltyPlan: {
    currentPage: 1,
    pageSize: 10,
    result: [],
    sortBy: CompletedLoyaltyPlanSortBy.nameAsc,
    totalItems: 0,
    totalPages: 1,
    query: '',
  },
};

export const loyaltyPlanSlice = createSlice({
  name: 'loyaltyPlan',
  initialState,
  reducers: {
    setLoyaltyPlan(state, { payload }) {
      state.loyaltyPlan = payload;
    },

    setPersonsSearchTerm(state, { payload }) {
      state.personsSearchTerm = payload;
    },

    clearPersonsSearchTerm(state) {
      state.personsSearchTerm = '';
    },

    setCompletedPlansSearchTerm(state, { payload }) {
      state.completedPlansSearchTerm = payload;
    },

    clearCompletedPlansSearchTerm(state) {
      state.completedPlansSearchTerm = '';
    },

    clearPaginatedLoyaltyPlanPersonsStatus(state) {
      state.fetchPaginatedLoyaltyPlanPersonsStatus = undefined;
    },

    clearPaginatedCompletedLoyaltyPlanStatus(state) {
      state.fetchPaginatedCompletedLoyaltyPlanStatus = undefined;
    },
  },
  extraReducers: {
    [fetchLoyaltyPlan.fulfilled.type]: (state, { payload }) => {
      state.loyaltyPlan = payload;
    },
    [fetchLoyaltyPlanStatistics.pending.type]: (state) => {
      state.fetchLoyaltyPlanStatisticsStatus = 'pending';
    },
    [fetchLoyaltyPlanStatistics.fulfilled.type]: (state, { payload }) => {
      state.fetchLoyaltyPlanStatisticsStatus = 'fulfilled';
      state.loyaltyPlanStatistics = payload;
    },
    [fetchLoyaltyPlanStatistics.rejected.type]: (state) => {
      state.fetchLoyaltyPlanStatisticsStatus = 'rejected';
    },
    [fetchPaginatedLoyaltyPlanPersons.pending.type]: (state) => {
      state.fetchPaginatedLoyaltyPlanPersonsStatus = 'pending';
    },
    [fetchPaginatedLoyaltyPlanPersons.fulfilled.type]: (state, { payload }) => {
      state.fetchPaginatedLoyaltyPlanPersonsStatus = 'fulfilled';
      state.paginatedLoyaltyPlanPersons = payload;
    },
    [fetchPaginatedLoyaltyPlanPersons.rejected.type]: (state) => {
      state.fetchPaginatedLoyaltyPlanPersonsStatus = 'rejected';
    },
    [fetchPaginatedCompletedLoyaltyPlans.pending.type]: (state) => {
      state.fetchPaginatedCompletedLoyaltyPlanStatus = 'pending';
    },
    [fetchPaginatedCompletedLoyaltyPlans.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.fetchPaginatedCompletedLoyaltyPlanStatus = 'fulfilled';
      state.paginatedCompletedLoyaltyPlan = payload;
    },
    [fetchPaginatedCompletedLoyaltyPlans.rejected.type]: (state) => {
      state.fetchPaginatedCompletedLoyaltyPlanStatus = 'rejected';
    },
  },
});

export default loyaltyPlanSlice.reducer;
export const {
  setLoyaltyPlan,
  clearPaginatedLoyaltyPlanPersonsStatus,
  setPersonsSearchTerm,
  clearPersonsSearchTerm,
  setCompletedPlansSearchTerm,
  clearCompletedPlansSearchTerm,
  clearPaginatedCompletedLoyaltyPlanStatus,
} = loyaltyPlanSlice.actions;
