/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { UaiRangoConfig } from '@wls-solucoes/lets-eat-types';
import { fetchUaiRangoConfig } from './actions';

interface UaiRangoState {
  config?: UaiRangoConfig;
}

const initialState: UaiRangoState = {};

export const uaiRangoSlice = createSlice({
  name: 'uaiRango',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUaiRangoConfig.fulfilled.type]: (state, { payload }) => {
      state.config = payload;
    },
  },
});

export default uaiRangoSlice.reducer;
