import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';
import { chatBotService } from '../../../shared/services/chatBot';

export const fetchChatbotStatus = createAsyncThunk(
  'Chatbot/fetchChatbotStatus',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return chatBotService.getChatBotStatus().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
