import { AxiosResponse } from 'axios';
import { ProductForm, SimplifiedProduct } from '../models/product';
import Api from './api';

const base = '/api/Item';
const PAGE_SIZE = 20;

function insertOne(product: ProductForm): Promise<AxiosResponse> {
  return Api.post(
    base,
    {
      ...product,
      optionals: product.optionals.map((o) => o.guid),
      prices: [
        ...product.prices.map((price) => {
          return {
            ...price,
            optionals: price.optionals?.map((o) => o.guid),
          };
        }),
      ],
    },
    {
      headers: {
        authHeader: true,
        successMessage: 'Item cadastrado com sucesso.',
      },
    }
  );
}

function getItemsSimplified(): Promise<SimplifiedProduct[]> {
  return Api.get(`${base}/simplified`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getPaginated(
  page: number,
  categoryGuid: string
): Promise<AxiosResponse> {
  return Api.get(
    `${base}?page=${page}&pageSize=${PAGE_SIZE}&categoryGuid=${categoryGuid}`,
    {
      headers: {
        authHeader: true,
      },
    }
  );
}

function getItemPaginationInfo(
  itemGuid: string,
  categoryGuid: string
): Promise<AxiosResponse> {
  return Api.get(`${base}/${itemGuid}`, {
    params: {
      categoryGuid,
      pageSize: PAGE_SIZE,
    },
    headers: {
      authHeader: true,
    },
  });
}

function updateOne(product: ProductForm): Promise<AxiosResponse> {
  return Api.put(
    base,
    {
      ...product,
      optionals: product.optionals.map((o) => o.guid),
      prices: [
        ...product.prices.map((price) => {
          return {
            ...price,
            optionals: price.optionals?.map((o) => o.guid),
          };
        }),
      ],
    },
    {
      headers: {
        authHeader: true,
        successMessage: 'Item atualizado com sucesso.',
      },
    }
  );
}

function removeProduct(itemGuid: string): Promise<AxiosResponse> {
  return Api.delete(`${base}/${itemGuid}`, {
    headers: {
      authHeader: true,
      successMessage: 'Item removido com sucesso.',
    },
  });
}

function defineCover(
  itemGuid: string,
  imageGuid: string
): Promise<AxiosResponse> {
  return Api.patch(
    `${base}/${itemGuid}/images/${imageGuid}/cover`,
    {},
    {
      headers: {
        authHeader: true,
        successMessage: 'Capa definida com sucesso.',
      },
    }
  );
}

function deleteImage(
  itemGuid: string,
  imageGuid: string
): Promise<AxiosResponse> {
  return Api.delete(`${base}/${itemGuid}/images/${imageGuid}`, {
    headers: {
      authHeader: true,
      successMessage: 'Imagem removida com sucesso.',
    },
  });
}

function changeVisibility(itemGuid: string): Promise<AxiosResponse> {
  return Api.patch(
    `${base}/${itemGuid}/visibility`,
    {},
    {
      headers: {
        authHeader: true,
        successMessage: 'Visibilidade alterada com sucesso.',
      },
    }
  );
}

function addImage(itemGuid: string, imageFile: File): Promise<AxiosResponse> {
  const formData = new FormData();
  formData.append('file', imageFile);
  return Api.post(`${base}/${itemGuid}/images/`, formData, {
    headers: {
      authHeader: true,
    },
  });
}

function getPaginatedPanelCatalog(): Promise<AxiosResponse> {
  return Api.get(`${base}/catalog`, {
    headers: {
      authHeader: true,
    },
  });
}

function updatePriority(
  itemGuid: string,
  priority: number
): Promise<AxiosResponse> {
  return Api.put(
    `${base}/${itemGuid}/priority/${priority}`,
    {},
    {
      headers: { authHeader: true },
    }
  );
}

function addItemSuggestion(
  itemGuid: string,
  itemRecommendationGuid: string
): Promise<AxiosResponse> {
  return Api.post(
    `${base}/${itemGuid}/recommendation/${itemRecommendationGuid}`,
    {},
    {
      headers: {
        authHeader: true,
        successMessage: 'Recomendação adicionada com sucesso!',
      },
    }
  );
}

function removeItemSuggestion(itemGuid: string): Promise<AxiosResponse> {
  return Api.delete(`${base}/${itemGuid}/recommendation`, {
    headers: {
      authHeader: true,
      successMessage: 'Recomendação removida com sucesso!',
    },
  });
}

export const productServices = {
  insertOne,
  updateOne,
  defineCover,
  deleteImage,
  addImage,
  removeProduct,
  getPaginated,
  getItemsSimplified,
  getItemPaginationInfo,
  changeVisibility,
  getPaginatedPanelCatalog,
  updatePriority,
  addItemSuggestion,
  removeItemSuggestion,
};
