import { createSlice } from '@reduxjs/toolkit';
import { CouponsState } from '../../types';
import { fetchCoupons } from './actions';

const initialState: CouponsState = {
  paginatedCoupons: {
    currentPage: 1,
    totalPages: 1,
    hasMore: true,
    pageSize: 6,
    publicDiscountCode: [],
    result: [],
    totalItems: 0,
  },
  isFetchingCoupons: false,
};

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setPaginatedCoupons(state, { payload }) {
      state.paginatedCoupons = payload;
    },
    updateCoupon(state, { payload }) {
      const { paginatedCoupons } = state;

      if (paginatedCoupons.result.length === 0) return;

      const couponIndex = paginatedCoupons.result.findIndex(
        (coupon) => coupon.guid === payload.guid
      );

      if (couponIndex === -1) return;

      paginatedCoupons.result[couponIndex] = payload;
    },
  },
  extraReducers: {
    [fetchCoupons.pending.type]: (state) => {
      state.isFetchingCoupons = true;
    },
    [fetchCoupons.fulfilled.type]: (state, { payload }) => {
      state.paginatedCoupons = payload;
      state.isFetchingCoupons = false;
    },
    [fetchCoupons.rejected.type]: (state) => {
      state.isFetchingCoupons = false;
    },
  },
});

export default couponsSlice.reducer;
export const { setPaginatedCoupons, updateCoupon } = couponsSlice.actions;
