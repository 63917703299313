import { Establishment } from '@wls-solucoes/lets-eat-types';
import {
  ESTABLISHMENT_GET,
  ESTABLISHMENT_SET,
  EstablishmentState,
  GenericAction,
  LINE_OF_WORK_GET,
  OPENING_HOURS_SETTINGS_GET,
} from '../../types';
import { getSuccessType } from '../promiseHelpers';

const initialState: EstablishmentState = {};

const establishmentReducer = (
  state: EstablishmentState = initialState,
  action: GenericAction
): EstablishmentState => {
  switch (action.type) {
    case ESTABLISHMENT_SET:
      return {
        ...state,
        establishment: action.payload,
      };

    case getSuccessType(ESTABLISHMENT_GET):
      return {
        ...state,
        establishment: action.payload,
      };

    case getSuccessType(OPENING_HOURS_SETTINGS_GET):
      return {
        ...state,
        establishment: {
          ...(state.establishment as Establishment),
          isOpen: action.payload.isOpen,

          closedReasons: action.payload.closedReasons,
          forcedClose: action.payload.forcedClose,
          forcedOpen: action.payload.forcedOpen,
          nextClosingTimeAfterForcedOpen:
            action.payload.nextClosingTimeAfterForcedOpen,
          nextOpeningTimeAfterForcedClose:
            action.payload.nextOpeningTimeAfterForcedClose,
          openingHoursSettings: action.payload,
        },
      };

    case getSuccessType(LINE_OF_WORK_GET):
      return {
        ...state,
        linesOfWork: action.payload,
      };

    default:
      return state;
  }
};

export default establishmentReducer;
