/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IndicatorsState } from '../../types';
import {
  getLastSevenDaysIndicators,
  getLastThirtyDaysIndicators,
  getThisMonthIndicators,
  getTodayIndicators,
  getYesterdayIndicators,
} from './actions';

const initialState: IndicatorsState = {};

export const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState,
  reducers: {},
  extraReducers: {
    [getTodayIndicators.fulfilled.type]: (state, { payload }) => {
      state.today = payload;
    },
    [getYesterdayIndicators.fulfilled.type]: (state, { payload }) => {
      state.yesterday = payload;
    },
    [getLastSevenDaysIndicators.fulfilled.type]: (state, { payload }) => {
      state.lastSevenDays = payload;
    },
    [getLastThirtyDaysIndicators.fulfilled.type]: (state, { payload }) => {
      state.lastThirtyDays = payload;
    },
    [getThisMonthIndicators.fulfilled.type]: (state, { payload }) => {
      state.thisMonth = payload;
    },
  },
});

export default indicatorsSlice.reducer;
