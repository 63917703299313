import { GenericAction, EstablishmentUserState, ESTABLISHMENT_USER_GET } from '../../types';
import { getSuccessType } from '../promiseHelpers';


const initialState: EstablishmentUserState = {};

const establishmentUserReducer = (state: EstablishmentUserState = initialState, action: GenericAction): EstablishmentUserState => {
    switch (action.type) {
        case getSuccessType(ESTABLISHMENT_USER_GET):
            return {
                ...state,
                establishmentUsers: action.payload
            }
        default:
            return state;
    }
}

export default establishmentUserReducer;
