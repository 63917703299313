import { ModelConfigs, PrintConfigs } from '@wls-solucoes/lets-eat-core';
import { OrderStatus } from './order/status';

export enum PrinterType {
  a4,
  thermal,
  simplifiedThermal,
}

export enum PrinterStyleTypes {
  web,
  qztray,
  server,
  printModel,
  printGroupsConfig
}

export enum PaperSize {
  mm80 = '75',
  mm76 = '70',
  mm58 = '54',
}

export type defaultPrintersProps = {
  nickname: string;
  printer: string;
  printType: PrinterType;
  paperSize: PaperSize;
  showCategory: boolean;
  hideDescription: boolean;
  showValues: boolean;
};

export type automaticPrintersProps = {
  nickname: string;
  status: OrderStatus;
  printType: PrinterType;
  showCategory: boolean;
  hideDescription: boolean;
  showValues: boolean;
};

export type PrinterModel = {
  defaultPrinters: defaultPrintersProps[];
  automaticPrinters: automaticPrintersProps[];
  PrinterStyle?: PrinterStyleTypes;
};

export type DesktopPrinterModel = {
  serverPrintUrl: string;
  serverPrintConfigs: PrintConfigs;
  serverPrintModel: ModelConfigs;
};
