import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cardFlagReducer from './CardFlag/reducer';
import establishmentUser from './EstablishmentUser/reducer';
import IndicatorsReducer from './Indicators/reducer';
import postReducer from './Post/reducer';
import PrinterConfigReducer from './PrinterConfig/reducer';
import aiqfomeReducer from './aiqfome/reducer';
import blockDialogReducer from './blockDialog/reducer';
import cashFlowReducer from './cashFlow/reducer';
import catalogReducer from './catalog/reducer';
import catalogGuideReducer from './catalogGuide/reducer';
import categoriesReducer from './categories/reducer';
import chatbotReducer from './chatbot/reducer';
import ConfigDialogReducer from './configDialog/reducer';
import couponsReducer from './coupons/reducer';
import DesktopPrinterConfigReducer from './desktopPrinter/reducer';
import establishmentReducer from './establishment/reducer';
import feeByAreaReducer from './feeByArea/reducer';
import feeByDistanceReducer from './feeByDistance/reducer';
import guideReducer from './guide/reducer';
import iFoodReducer from './iFood/reducer';
import kitchenReducer from './kitchen/reducer';
import localAddressReducer from './localAddress/reducer';
import localOrderSettingsReducer from './localOrderSettings/reducer';
import loyaltyPlanReducer from './loyaltyPlan/reducer';
import neighborhoodsReducer from './neighborhood/reducer';
import notificationsReducer from './notification/reducer';
import openingHoursSettingsReducer from './openingHoursSettings/reducer';
import optionalReducer from './optional/reducer';
import orderSettingsReducer from './orderSettings/reducer';
import OrderReducer from './orders/reducer';
import ordersV3Reducer from './ordersV3/reducer';
import panelCatalogReducer from './panelCatalog/reducer';
import panelOrderReducer from './panelOrder/reducer';
import personReducer from './person/reducer';
import planReducer from './plan/reducer';
import printGroupsReducer from './printGroups/reducer';
import productReducer from './product/reducer';
import productV3Reducer from './productV3/reducer';
import progressIndicatorReducer from './progressIndicator/progressIndicatorReducer';
import referralProgramReducer from './referralProgram/reducer';
import reportsReducer from './reports/reducer';
import resourceBlockedReducer from './resourceBlocked/reducer';
import sideBarReducer from './sideBar/reducer';
import surveyReducer from './survey/reducer';
import themeReducer from './theme/reducer';
import timeZoneReducer from './timeZone/reducer';
import uaiRangoReducer from './uaiRango/reducer';
import uiControllerReducer from './uiController/reducer';
import { userReducer } from './user/userReducer';
import userPermissionReducer from './userPermission/reducer';
import preUpdateOrderReducer from './preUpdateOrder/reducer';

const persistConfig = {
  key: 'root_v3',
  storage,
  whitelist: [
    'notificationsReducer',
    'OrderReducer',
    'PrinterConfigReducer',
    'ordersV3Reducer',
    'userReducer',
    'cashFlowReducer',
    'panelOrderReducer',
    'catalogGuideReducer',
    'sideBarReducer',
    'DesktopPrinterConfigReducer',
    'printGroupsReducer',
    'localOrderSettingsReducer',
    'themeReducer',
  ],
};

const rootReducer = combineReducers({
  progressIndicatorReducer,
  feeByDistanceReducer,
  feeByAreaReducer,
  themeReducer,
  establishmentReducer,
  userReducer,
  categoriesReducer,
  productReducer,
  OrderReducer,
  ordersV3Reducer,
  optionalReducer,
  catalogReducer,
  catalogGuideReducer,
  cardFlagReducer,
  openingHoursSettingsReducer,
  cashFlowReducer,
  localAddressReducer,
  neighborhoodsReducer,
  planReducer,
  notificationsReducer,
  establishmentUser,
  postReducer,
  IndicatorsReducer,
  PrinterConfigReducer,
  couponsReducer,
  panelCatalogReducer,
  panelOrderReducer,
  personReducer,
  reportsReducer,
  guideReducer,
  sideBarReducer,
  loyaltyPlanReducer,
  DesktopPrinterConfigReducer,
  iFoodReducer,
  aiqfomeReducer,
  ConfigDialogReducer,
  userPermissionReducer,
  surveyReducer,
  printGroupsReducer,
  orderSettingsReducer,
  productV3Reducer,
  chatbotReducer,
  localOrderSettingsReducer,
  blockDialogReducer,
  uiControllerReducer,
  kitchenReducer,
  timeZoneReducer,
  referralProgramReducer,
  resourceBlockedReducer,
  uaiRangoReducer,
  preUpdateOrderReducer,
});

const root = (state: any, action: any) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);

export default persistReducer(persistConfig, root);
