import { createAsyncThunk } from '@reduxjs/toolkit';
import { productServices } from '../../../shared/services/product';
import {
  progressIndicatorAdd,
  progressIndicatorRemove
} from '../progressIndicator/progressIndicatorActions';

export const fetchPanelCatalog = createAsyncThunk(
  'panelCatalog/fetch',
  async (params, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return productServices.getPaginatedPanelCatalog().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
