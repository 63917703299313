import { GenericAction, OPTIONAL_GET, OptionalState } from '../../types';
import { getFailedType, getStartType, getSuccessType } from '../promiseHelpers';

const initialState: OptionalState = {
  isFetchingOptionals: true,
};

const optionalReducer = (
  state: OptionalState = initialState,
  action: GenericAction
): OptionalState => {
  switch (action.type) {
    case getStartType(OPTIONAL_GET):
      return {
        ...state,
        isFetchingOptionals: true,
      };
    case getSuccessType(OPTIONAL_GET):
      return {
        ...state,
        optionals: action.payload,
        isFetchingOptionals: false,
      };
    case getFailedType(OPTIONAL_GET):
      return {
        ...state,
        isFetchingOptionals: false,
      };
    default:
      return state;
  }
};

export default optionalReducer;
