import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CouponsPaginated,
  FetchPaginatedRequestParams,
} from '@wls-solucoes/lets-eat-types';
import { discountCodeService } from '../../../shared/services/api';
import { store } from '../../store';

export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async (params: FetchPaginatedRequestParams) => {
    const { couponsReducer } = store.getState();
    const { currentPage, pageSize, query } =
      couponsReducer.paginatedCoupons as CouponsPaginated;

    return discountCodeService.getPaginated({
      page: params.page ?? currentPage,
      pageSize: params.pageSize ?? pageSize,
      query: params.query ?? query,
    });
  }
);
