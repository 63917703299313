import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
    marginTop: 16,
  },
}));

type IndicationProps = {
  length: number;
  currentStep: number;
};

const Indication: React.FC<IndicationProps> = ({ length, currentStep }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {Array.from(Array(length).keys()).map((i) => {
        const isActive = i === currentStep;
        return (
          <Box
            key={i}
            width={isActive ? 8 : 6}
            height={isActive ? 8 : 6}
            borderRadius="50%"
            bgcolor={isActive ? 'primary.main' : '#CCCCCC'}
          />
        );
      })}
    </Box>
  );
};

export default Indication;
