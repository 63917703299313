import { Category } from '@wls-solucoes/lets-eat-types';
import {
  CATEGORIES_GET,
  CATEGORIES_REORDER,
  CategoriesState,
  GenericAction,
} from '../../types';
import { getStartType, getSuccessType } from '../promiseHelpers';

const initialState: CategoriesState = {};

const categoriesReducer = (
  state: CategoriesState = initialState,
  action: GenericAction
): CategoriesState => {
  switch (action.type) {
    case getSuccessType(CATEGORIES_GET):
      return {
        ...state,
        categories: action.payload,
      };
    case getStartType(CATEGORIES_REORDER): {
      const category = (state.categories as Category[]).find(
        (x) => x.guid === action.payload.categoryGuid
      );
      const newArr = [...(state.categories as Category[])].filter(
        (x) => x.guid !== action.payload.categoryGuid
      );

      newArr.splice(action.payload.priority, 0, category as Category);

      return {
        ...state,
        categories: newArr,
      };
    }
    case getSuccessType(CATEGORIES_REORDER): {
      const category = (state.categories as Category[]).find(
        (x) => x.guid === action.payload.guid
      );
      const newArr = [...(state.categories as Category[])].filter(
        (x) => x.guid !== action.payload.guid
      );

      newArr.splice(action.payload.priority, 0, category as Category);

      return {
        ...state,
        categories: newArr,
      };
    }
    default:
      return state;
  }
};

export default categoriesReducer;
