import { createSlice } from '@reduxjs/toolkit';
import { UserPermissionState } from '../../types';
import { fetchUserPermissions } from './actions';

const initialState: UserPermissionState = {
  permissions: [],
};

export const userPermissionSlice = createSlice({
  name: 'userPermission',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserPermissions.fulfilled.type]: (state, { payload }) => {
      state.permissions = payload;
    },
  },
});

export default userPermissionSlice.reducer;
