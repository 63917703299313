import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginatedOrdersRequestParams } from '../../../shared/models/order/v3/orderPaginatedData';
import { orderService } from '../../../shared/services/orderV3';
import { OrderV3State, RootState } from '../../types';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';
import { getOwnedEstablishments } from '../../../helpers/hooks/getOwnedEstablishments';

export const fetchPaginatedOrders = createAsyncThunk(
  'orders/fetchPaginatedOrders',
  async (
    params: PaginatedOrdersRequestParams,
    { dispatch, getState, requestId }
  ) => {
    const { ordersV3Reducer } = getState() as RootState;
    const ordersState = ordersV3Reducer as OrderV3State;
    const {
      page,
      status,
      query,
      hideFinished,
      hideCancelled,
      startDate,
      endDate,
    } = params;

    dispatch(progressIndicatorAdd(requestId));

    const ownedEstablishments = getOwnedEstablishments();

    return orderService
      .getPaginated(
        ownedEstablishments,
        page ?? 1,
        status ?? '',
        query ?? '',
        startDate ?? '',
        endDate ?? '',
        hideFinished ?? ordersState.filters?.hideFinished,
        hideCancelled ?? ordersState.filters?.hideCancelled
      )
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);

export const refreshPaginatedOrders = createAsyncThunk(
  'orders/fetchPaginatedOrders',
  async (params, { getState }) => {
    const { ordersV3Reducer } = getState() as RootState;
    const ordersState = ordersV3Reducer as OrderV3State;

    const ownedEstablishments = getOwnedEstablishments();

    return orderService.getPaginated(
      ownedEstablishments,
      ordersState.orders?.currentPage ?? 1,
      ordersState.orders?.status ?? '',
      ordersState.orders?.query ?? '',
      ordersState.filters?.startDate ?? '',
      ordersState.filters?.endDate ?? '',
      ordersState.filters?.hideFinished,
      ordersState.filters?.hideCancelled
    );
  }
);

export const fetchNewOrder = createAsyncThunk(
  'orders/fetchNewOrder',
  async (orderGuid: string) => orderService.getOrder(orderGuid)
);

export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (orderGuid: string) => orderService.getOrder(orderGuid)
);

interface FetchedOrderDetailsParams {
  orderGuid: string;
  callBack?: () => void;
}

export const fetchOrderDetails = createAsyncThunk(
  'orders/fetchOrderDetails',
  async (params: FetchedOrderDetailsParams, { dispatch, requestId }) => {
    const { orderGuid, callBack } = params;

    dispatch(progressIndicatorAdd(requestId));

    return orderService.getDetails(orderGuid).finally(() => {
      dispatch(progressIndicatorRemove(requestId));

      setTimeout(() => callBack?.(), 150);
    });
  }
);
