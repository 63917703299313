/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { BlockDialogState } from '../../types';

const initialState: BlockDialogState = {
  isOpen: false,
};

export const blockDialogSlice = createSlice({
  name: 'blockDialog',
  initialState,
  reducers: {
    openBlockDialog(state) {
      state.isOpen = true;
    },

    closeBlockDialog(state) {
      state.isOpen = false;
    },
  },
});

export default blockDialogSlice.reducer;
export const { openBlockDialog, closeBlockDialog } = blockDialogSlice.actions;
