import { Order } from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import Api from './api';

const base = '/api/order';

function getPaginated(
  ownedEstablishments: string[],
  page: number,
  status: string,
  query: string,
  startDate: string,
  endDate: string,
  hideFinished = false,
  hideCanceled = false
): any {
  const queryToUse = btoa(unescape(encodeURIComponent(query)));
  const ownedEstablishmentsToUse = ownedEstablishments.join(
    '&establishmentGuids='
  );
  const url = `${base}?page=${page}&pageSize=18&status=${status}&hideFinished=${hideFinished}&hideCanceled=${hideCanceled}&startDate=${startDate}&endDate=${endDate}&query=${queryToUse}&establishmentGuids=${ownedEstablishmentsToUse}`;

  return Api.get(url, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getOrder(orderGuid: string): any {
  const url = `${base}/${orderGuid}`;

  return Api.get(url, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getDetails(orderGuid: string): Promise<AxiosResponse<Order>> {
  const url = `${base}/${orderGuid}`;
  return Api.get<Order>(url, {
    headers: {
      authHeader: true,
    },
  });
}

async function confirmPayment(
  orderGuid: string,
  paymentGuid: string
): Promise<void> {
  return Api.post(
    `${base}/${orderGuid}/payments/${paymentGuid}/confirm`,
    undefined,
    {
      headers: {
        'x-api-version': 3,
        authHeader: true,
      },
    }
  ).then();
}

export const orderService = {
  getPaginated,
  getOrder,
  getDetails,
  confirmPayment,
};
