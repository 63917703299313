import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CompletedLoyaltyPlanPaginatedData,
  PersonLoyaltyPlanPaginatedData,
} from '@wls-solucoes/lets-eat-types';
import { loyaltyPlanServices } from '../../../shared/services/loyaltyPlan';
import { store } from '../../store';
import {
  FetchCompletedLoyaltyPlanPaginatedRequestParams,
  FetchLoyaltyPlanPersonsPaginatedRequestParams,
} from '../../types';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchLoyaltyPlan = createAsyncThunk(
  'loyaltyPlan/fetchLoyaltyPlan',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return loyaltyPlanServices.getPlan().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchLoyaltyPlanStatistics = createAsyncThunk(
  'loyaltyPlan/fetchLoyaltyPlanStatistics',
  async () => {
    return loyaltyPlanServices.getPlanStatistics();
  }
);

export const fetchPaginatedLoyaltyPlanPersons = createAsyncThunk(
  'loyaltyPlan/fetchPaginatedLoyaltyPlanPersons',
  async (params?: FetchLoyaltyPlanPersonsPaginatedRequestParams) => {
    const { loyaltyPlanReducer } = store.getState();
    const {
      currentPage: page,
      pageSize,
      query,
      sortBy,
    } = loyaltyPlanReducer.paginatedLoyaltyPlanPersons as PersonLoyaltyPlanPaginatedData;

    return loyaltyPlanServices.getPlanClients({
      page: params?.page ?? page,
      pageSize: params?.pageSize ?? pageSize,
      query: params?.query ?? query,
      sortBy: params?.sortBy ?? sortBy,
    });
  }
);

export const fetchPaginatedCompletedLoyaltyPlans = createAsyncThunk(
  'loyaltyPlan/fetchPaginatedCompletedLoyaltyPlans',
  async (params?: FetchCompletedLoyaltyPlanPaginatedRequestParams) => {
    const { loyaltyPlanReducer } = store.getState();
    const {
      currentPage: page,
      pageSize,
      query,
      sortBy,
    } = loyaltyPlanReducer.paginatedCompletedLoyaltyPlan as CompletedLoyaltyPlanPaginatedData;

    return loyaltyPlanServices.getCompletedPlans({
      page: params?.page ?? page,
      pageSize: params?.pageSize ?? pageSize,
      query: params?.query ?? query,
      sortBy: params?.sortBy ?? sortBy,
    });
  }
);
