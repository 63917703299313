import { createSlice } from '@reduxjs/toolkit';
import { ChatbotState } from '../../types';
import { fetchChatbotStatus } from './actions';

const initialState: ChatbotState = {
  unreadMessagesCount: 0,
  hideChatbot: false,
};

export const ChatbotSlice = createSlice({
  name: 'Chatbot',
  initialState,
  reducers: {
    setUnreadMessagesCount: (state, { payload }) => {
      state.unreadMessagesCount = payload;
    },
    setHideChatbot: (state, { payload }) => {
      state.hideChatbot = payload;
    },
  },
  extraReducers: {
    [fetchChatbotStatus.fulfilled.type]: (state, { payload }) => {
      state.chatbot = payload;
    },
  },
});

export default ChatbotSlice.reducer;
export const { setUnreadMessagesCount, setHideChatbot } = ChatbotSlice.actions;
