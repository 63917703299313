import { createSlice } from '@reduxjs/toolkit';
import { FeeByDistanceState } from '../../types';
import { feeByDistanceGet } from './actions';

const initialState: FeeByDistanceState = { feeByDistance: [] };

export const feeByDistanceSlice = createSlice({
  name: 'feeByDistance',
  initialState,
  reducers: {},
  extraReducers: {
    [feeByDistanceGet.fulfilled.type]: (state, { payload }) => {
      state.feeByDistance = payload;
    },
  },
});

export default feeByDistanceSlice.reducer;
