import { createSlice } from '@reduxjs/toolkit';
import { IFoodState } from '../../types';
import { fetchIFoodConfiguration } from './actions';

const initialState: IFoodState = {};

export const iFoodSlice = createSlice({
  name: 'iFood',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchIFoodConfiguration.fulfilled.type]: (state, { payload }) => {
      state.iFoodConfiguration = payload;
    },
  },
});

export default iFoodSlice.reducer;
