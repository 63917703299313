import { createSlice } from '@reduxjs/toolkit';
import { GenericStatus } from '@wls-solucoes/lets-eat-types';
import { SurveyState } from '../../types';
import { fetchSurvey, updateSurvey, updateSurveyStatus } from './actions';

const initialState: SurveyState = {};

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    addQuestion(state, { payload }) {
      if (!state.survey) return;

      state.survey.questions = [...state.survey.questions, payload];
    },
  },
  extraReducers: {
    [fetchSurvey.fulfilled.type]: (state, { payload }) => {
      state.survey = payload;
    },
    [updateSurvey.fulfilled.type]: (state, { payload }) => {
      state.survey = payload;
    },
    [updateSurveyStatus.fulfilled.type]: (state) => {
      if (!state.survey) return;

      const { survey } = state;

      state.survey = {
        ...survey,
        status:
          survey.status === GenericStatus.activated
            ? GenericStatus.deactivated
            : GenericStatus.activated,
      };
    },
  },
});

export default surveySlice.reducer;
export const { addQuestion } = surveySlice.actions;
