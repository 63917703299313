import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentType, Person } from '@wls-solucoes/lets-eat-types';
import { isValidCNPJ } from '../../../helpers/validators/cnpj';
import { isValidCpf } from '../../../helpers/validators/cpf';
import { PersonPaginatedData } from '../../../shared/models/person';
import { personService } from '../../../shared/services/person';
import { store } from '../../store';
import {
  FetchPaginatedPersonsRequestParams,
  PersonAddressRequestParams,
} from '../../types';
import { setSelectedClient } from '../panelOrder/reducer';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchPaginatedPerson = createAsyncThunk(
  'person/fetchPaginated',
  async (params?: FetchPaginatedPersonsRequestParams) => {
    const { personReducer } = store.getState();
    const {
      currentPage: page,
      pageSize,
      query,
      sortBy,
      personFilterBy,
    } = personReducer.personPaginatedData as PersonPaginatedData;

    return personService.getPaginated({
      page: params?.page ?? page,
      pageSize: params?.pageSize ?? pageSize,
      query: params?.query ?? query,
      sortBy: params?.sortBy ?? sortBy,
      filterBy: params?.filterBy ?? personFilterBy,
    });
  }
);

export const refreshPaginatedPerson = createAsyncThunk(
  'person/refreshPaginated',
  async (params, { dispatch }) => {
    const { personReducer } = store.getState();
    const {
      currentPage: page,
      pageSize,
      query,
      sortBy,
    } = personReducer.personPaginatedData;

    dispatch(fetchPaginatedPerson({ page, pageSize, query, sortBy }));
  }
);

export const changePerson = createAsyncThunk(
  'person/changeOne',
  async (person: Person, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return personService
      .updatePerson(person)
      .then(({ data }) => {
        const isDocumentValid =
          data.document &&
          ((data.documentType === DocumentType.cpf &&
            !isValidCpf(data.document)) ||
            (data.documentType === DocumentType.cnpj &&
              !isValidCNPJ(data.document)));

        dispatch(
          setSelectedClient({
            ...data,
            document: isDocumentValid ? data.document : undefined,
          })
        );

        dispatch(refreshPaginatedPerson());
      })
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const insertPersonAddress = createAsyncThunk(
  'person/insertAddress',
  async (params: PersonAddressRequestParams, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return personService
      .addPersonAddress(params)
      .then((res) => {
        dispatch(setSelectedClient(res.data));

        dispatch(refreshPaginatedPerson());
      })
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const changePersonAddress = createAsyncThunk(
  'person/changeAddress',
  async (params: PersonAddressRequestParams, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return personService
      .updatePersonAddress(params)
      .then((res) => {
        dispatch(setSelectedClient(res.data));

        dispatch(refreshPaginatedPerson());
      })
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const removePerson = createAsyncThunk(
  'person/removeOne',
  async (personGuid: string, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return personService
      .deletePerson(personGuid)
      .then(() => {
        dispatch(refreshPaginatedPerson());
      })
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);
