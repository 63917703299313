import { env } from '../../env';

const pageToUse = (page: string): string => {
  const split = page.split('/');

  const pageNameToUse = split[split.length - 1];
  switch (pageNameToUse) {
    case 'itemDetails':
      return 'itemDetails';
    case 'cart':
      return 'cart';
    case 'ClientOrder':
      return 'ClientOrder';
    default:
      return page;
  }
};

const getGtag = (): any => (window as any).gtag;

function createCustomDimensions(customDimensions: object): void {
  const gtag = getGtag();

  gtag?.('config', env.REACT_APP_GA_ID, {
    'custom_map': customDimensions,
  })
}

function logPageView(page: string): void {
  const gtag = getGtag();
  const newPageName = pageToUse(page);
  gtag?.('config', env.REACT_APP_GA_ID, {
    'page_title': newPageName,
    'page_path': newPageName,
  });

  if (!env.isProd) {
    console.log(`Page View logged for: ${newPageName}`);
  }
}

function eventsGA(event: string, establishmentGuid: string, options?: object): void {
  const gtag = getGtag();
  gtag?.('event', event, {'establishment_guid': establishmentGuid, ...options});

  if (!env.isProd) {
    console.log(`GA event captured: ${event}`);
  }
}

function setGAUserId(userId: string): void {
  const gtag = getGtag();

  gtag?.('set', 'user_id', userId);

  gtag?.('set', 'user_properties', {
    'crm_id': userId,
  });

  if (!env.isProd) {
    console.log(`GA user id set: ${userId}`);
  }
}

export const analytics = {
  createCustomDimensions,
  logPageView,
  eventsGA,
  setGAUserId,
};
