/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { SideBarState } from '../../types';

const initialState: SideBarState = {
  isRetracted: false,
};

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    toggleRetract(state) {
      state.isRetracted = !state.isRetracted;
    },
  },
});

export default sideBarSlice.reducer;
export const { toggleRetract } = sideBarSlice.actions;
