import jwtDecode from 'jwt-decode';
import { AuthService } from '../../shared/services/authService';

export const getOwnedEstablishments = () => {
  const { accessToken } = AuthService.getAuth();
  const decodedToken = (jwtDecode(accessToken) ?? {}) as any;

  if (!decodedToken.ownedEstablishments) return [];
  return Array.isArray(decodedToken.ownedEstablishments)
    ? decodedToken.ownedEstablishments
    : [decodedToken.ownedEstablishments];
};
