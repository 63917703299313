import { v4 as uuidv4 } from 'uuid';
import { Address } from '../models/address';

const key = 'addresses_v3';

const getAddresses = (): Array<Address> => {
  const storage = localStorage.getItem(key);
  if (storage === null) {
    return new Array<Address>();
  }
  return JSON.parse(storage);
};

const setAddresses = (addresses: Array<Address>): void => {
  localStorage.setItem(key, JSON.stringify(addresses));
};

const addAddress = (address: Address): Array<Address> => {
  const addresses = getAddresses();
  if (address.localGuid === undefined) {
    const addressToPersist = {
      ...address,
      localGuid: uuidv4(),
    };
    setAddresses([...addresses, addressToPersist]);
  } else {
    const index = addresses.findIndex((a) => a.localGuid === address.localGuid);
    if (index !== undefined) {
      addresses[index] = address;
      setAddresses(addresses);
    }
  }
  return getAddresses();
};

const removeAddress = (localGuid: string): Array<Address> => {
  const addresses = getAddresses();

  let newArr: Address[] = [...addresses];

  newArr = newArr.filter((x) => x.localGuid !== localGuid);

  setAddresses(newArr);

  return getAddresses();
};

export const localAddressServices = {
  addAddress,
  getAddresses,
  removeAddress,
};
