import { createSlice } from '@reduxjs/toolkit';
import { Category, CategoryItems } from '@wls-solucoes/lets-eat-types';
import { ProductV3State } from '../../types';
import {
  changeCategoryStatus,
  changeItemStatus,
  fetchBlockedItems,
  fetchCategories,
  fetchSimplifiedCategories,
  filterCategories,
} from './actions';

const initialState: ProductV3State = {
  isFetchingCategories: false,
  isFetchingBlockedItems: false,
  isFetchingSimplifiedCategories: false,
  showCatalogGuide: false,
  simplifiedCategories: [],
  categoriesOnFetchingItems: [],
  categoriesOnLoading: [],
  itemsOnLoading: [],
  query: '',
};

const productV3Slice = createSlice({
  name: 'productV3',
  initialState,
  reducers: {
    setCategoryGuid(state, { payload }) {
      state.categoryGuid = payload;
    },
    setQuery(state, { payload }) {
      state.query = payload;
    },
    removeCategoryFromState(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      state.categories = currentCategories.filter(
        (category) => category.guid !== payload
      );
    },
    increaseCategoryItemsCount(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload
      );

      if (categoryToUpdateIndex === -1) return;

      currentCategories[categoryToUpdateIndex].itemsCount++;

      state.categories = currentCategories;
    },
    decreaseCategoryItemsCount(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload
      );

      if (categoryToUpdateIndex === -1) return;

      currentCategories[categoryToUpdateIndex].itemsCount--;

      state.categories = currentCategories;
    },
    updateCategoryData(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload.guid
      );

      if (categoryToUpdateIndex === -1) return;

      currentCategories[categoryToUpdateIndex] = payload;

      state.categories = currentCategories;
    },
    setCategories(state, { payload }) {
      state.categories = payload;
    },
    addCategoryOnFetchingItems(state, { payload }) {
      state.categoriesOnFetchingItems = [
        ...state.categoriesOnFetchingItems,
        payload,
      ];
    },
    removeCategoryOnFetchingItems(state, { payload }) {
      state.categoriesOnFetchingItems = state.categoriesOnFetchingItems.filter(
        (category) => category !== payload
      );
    },
    addCategoryOnLoading(state, { payload }) {
      state.categoriesOnLoading = [...state.categoriesOnLoading, payload];
    },
    removeCategoryOnLoading(state, { payload }) {
      state.categoriesOnLoading = state.categoriesOnLoading.filter(
        (category) => category !== payload
      );
    },
    addItemOnLoading(state, { payload }) {
      state.itemsOnLoading = [...state.itemsOnLoading, payload];
    },
    removeItemOnLoading(state, { payload }) {
      state.itemsOnLoading = state.itemsOnLoading.filter(
        (item) => item !== payload
      );
    },
    updateItemData(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload.categoryGuid
      );

      if (categoryToUpdateIndex === -1) return;

      const updatedCategory = currentCategories[categoryToUpdateIndex];

      if (!updatedCategory.items) return;

      const itemToUpdateIndex = updatedCategory.items.findIndex(
        (item) => item.guid === payload.guid
      );

      if (itemToUpdateIndex === -1) return;

      updatedCategory.items[itemToUpdateIndex] = payload;

      currentCategories[categoryToUpdateIndex] = updatedCategory;

      state.categories = currentCategories;
    },
    addItemToCategory(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload.categoryGuid
      );

      if (categoryToUpdateIndex === -1) return;

      const updatedCategory = currentCategories[categoryToUpdateIndex];

      if (!updatedCategory.items) return;

      updatedCategory.items = [...updatedCategory.items, payload];
      updatedCategory.itemsCount += 1;

      currentCategories[categoryToUpdateIndex] = updatedCategory;

      state.categories = currentCategories;
    },
    removeItemFromCategory(state, { payload }) {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload.categoryGuid
      );

      if (categoryToUpdateIndex === -1) return;

      const updatedCategory = currentCategories[categoryToUpdateIndex];

      updatedCategory.items = updatedCategory.items?.filter(
        (item) => item.guid !== payload.itemGuid
      );

      updatedCategory.itemsCount -= 1;

      currentCategories[categoryToUpdateIndex] = updatedCategory;

      state.categories = currentCategories;
    },
    setCategoryFilterValues(state, { payload }) {
      state.categoryFilterValues = payload;
    },
    toggleCatalogGuide(state) {
      state.showCatalogGuide = !state.showCatalogGuide;
    },
  },
  extraReducers: {
    [fetchCategories.pending.type]: (state) => {
      state.isFetchingCategories = true;
    },
    [fetchCategories.fulfilled.type]: (state, { payload }) => {
      state.categories = payload.map((category: Category) => ({
        ...category,
        items: [],
      }));
      state.categoryFilterValues = payload.map((category: Category) => ({
        guid: category.guid,
        name: category.name,
      }));
      state.query = '';
      state.categoryGuid = undefined;
      state.isFetchingCategories = false;
    },
    [fetchCategories.rejected.type]: (state) => {
      state.isFetchingCategories = false;
    },
    [fetchSimplifiedCategories.pending.type]: (state) => {
      state.isFetchingSimplifiedCategories = true;
    },
    [fetchSimplifiedCategories.fulfilled.type]: (state, { payload }) => {
      state.isFetchingSimplifiedCategories = false;

      state.simplifiedCategories = payload;
    },
    [fetchSimplifiedCategories.rejected.type]: (state) => {
      state.isFetchingSimplifiedCategories = false;
    },
    [filterCategories.pending.type]: (state) => {
      state.isFetchingCategories = true;
    },
    [filterCategories.fulfilled.type]: (state, { payload }) => {
      state.categories = payload.map((category: Category | CategoryItems) => ({
        ...category,
        items: typeof category.items === 'number' ? [] : category.items,
      }));
      state.isFetchingCategories = false;
    },
    [filterCategories.rejected.type]: (state) => {
      state.isFetchingCategories = false;
    },
    [changeCategoryStatus.fulfilled.type]: (state, { payload }) => {
      const updatedCategories = state.categories;

      if (!updatedCategories) return;

      const categoryToUpdateIndex = updatedCategories?.findIndex(
        (category) => category.guid === payload.guid
      );

      if (categoryToUpdateIndex === -1) return;

      updatedCategories[categoryToUpdateIndex].status = payload.status;

      state.categories = updatedCategories;
    },
    [fetchBlockedItems.pending.type]: (state) => {
      state.isFetchingBlockedItems = true;
    },
    [fetchBlockedItems.fulfilled.type]: (state, { payload }) => {
      state.blockedItems = payload;
      state.isFetchingBlockedItems = false;
    },
    [fetchBlockedItems.rejected.type]: (state, { payload }) => {
      state.blockedItems = payload;
      state.isFetchingBlockedItems = false;
    },
    [changeItemStatus.fulfilled.type]: (state, { payload }) => {
      const currentCategories = state.categories;

      if (!currentCategories) return;

      const categoryToUpdateIndex = currentCategories?.findIndex(
        (category) => category.guid === payload.categoryGuid
      );

      if (categoryToUpdateIndex === -1) return;

      const updatedCategory = currentCategories[categoryToUpdateIndex];

      if (!updatedCategory.items) return;

      const itemToUpdateIndex = updatedCategory.items.findIndex(
        (item) => item.guid === payload.guid
      );

      if (itemToUpdateIndex === -1) return;

      updatedCategory.items[itemToUpdateIndex].showOnWeb = payload.showOnWeb;

      currentCategories[categoryToUpdateIndex] = updatedCategory;

      state.categories = currentCategories;
    },
  },
});

export default productV3Slice.reducer;
export const {
  setCategoryGuid,
  setQuery,
  removeCategoryFromState,
  increaseCategoryItemsCount,
  decreaseCategoryItemsCount,
  updateCategoryData,
  setCategories,
  addCategoryOnFetchingItems,
  removeCategoryOnFetchingItems,
  addCategoryOnLoading,
  removeCategoryOnLoading,
  addItemOnLoading,
  removeItemOnLoading,
  updateItemData,
  addItemToCategory,
  removeItemFromCategory,
  setCategoryFilterValues,
  toggleCatalogGuide,
} = productV3Slice.actions;
