/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  PrinterModel,
  PrinterStyleTypes,
} from '../../../shared/models/printerType';

const initialState: PrinterModel = {
  automaticPrinters: [],
  defaultPrinters: [],
  PrinterStyle: PrinterStyleTypes.web,
};

export const PrinterConfigSlice = createSlice({
  name: 'PrinterConfig',
  initialState,
  reducers: {
    clearPrinterConfig: (state) => {
      state.automaticPrinters = [];
      state.defaultPrinters = [];
      state.PrinterStyle = PrinterStyleTypes.web;
    },
    setPrinterConfig: (state, { payload }) => {
      state.automaticPrinters = payload.automaticPrinters;
      state.defaultPrinters = payload.defaultPrinters;
      state.PrinterStyle = payload.PrinterStyle;
    },
  },
});

export default PrinterConfigSlice.reducer;
export const { clearPrinterConfig, setPrinterConfig } =
  PrinterConfigSlice.actions;
