import { Indicators } from '../models/order/Indicators';
import Api from './api';

const baseUrl = '/api/order/indicators';

function getTodayIndicators(): Promise<Indicators> {
  return Api.get(`${baseUrl}/today`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getYesterdayIndicators(): Promise<Indicators> {
  return Api.get(`${baseUrl}/yesterday`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getLastSevenDaysIndicators(): Promise<Indicators> {
  return Api.get(`${baseUrl}/last_7_days`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

function getLastThirtyDaysIndicators(): Promise<Indicators> {
  return Api.get(`${baseUrl}/last_30_days`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}
function getThisMonthIndicators(): Promise<Indicators> {
  return Api.get(`${baseUrl}/month`, {
    headers: {
      authHeader: true,
    },
  }).then((res) => res.data);
}

export const indicatorsService = {
  getTodayIndicators,
  getYesterdayIndicators,
  getLastSevenDaysIndicators,
  getLastThirtyDaysIndicators,
  getThisMonthIndicators,
};
