import {
  CompletedLoyaltyPlan,
  LoyaltyPlan,
  LoyaltyPlanRequestForm,
  LoyaltyPlanUsageStatistics,
  LoyaltyPoint,
  PersonLoyaltyPlanPaginatedData,
} from '@wls-solucoes/lets-eat-types';
import {
  FetchCompletedLoyaltyPlanPaginatedRequestParams,
  FetchLoyaltyPlanPersonsPaginatedRequestParams,
} from '../../redux/types';
import Api from './api';

const baseUrl = '/api/loyalty-plan';

async function createNewPlan(
  loyaltyPlan: LoyaltyPlanRequestForm
): Promise<LoyaltyPlan> {
  return Api.post(`${baseUrl}`, loyaltyPlan, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
      successMessage: 'Programa de Fidelidade criado com sucesso!',
    },
  }).then((res) => res.data);
}

async function editPlan(
  loyaltyPlan: LoyaltyPlanRequestForm
): Promise<LoyaltyPlan> {
  return Api.put(`${baseUrl}`, loyaltyPlan, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
      successMessage: 'Programa de Fidelidade atualizado com sucesso!',
    },
  }).then((res) => res.data);
}

async function getPlan(): Promise<LoyaltyPlan> {
  return Api.get(`${baseUrl}`, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getPlanStatistics(): Promise<LoyaltyPlanUsageStatistics> {
  return Api.get(`${baseUrl}/persons/statistics`, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getPlanClients(
  params: FetchLoyaltyPlanPersonsPaginatedRequestParams
): Promise<PersonLoyaltyPlanPaginatedData> {
  return Api.get(`${baseUrl}/persons`, {
    params,
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getClientPoints(clientGuid: string): Promise<LoyaltyPoint[]> {
  return Api.get(`${baseUrl}/persons/${clientGuid}/points`, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getCompletedPlans(
  params: FetchCompletedLoyaltyPlanPaginatedRequestParams
): Promise<CompletedLoyaltyPlan> {
  return Api.get(`${baseUrl}/completed-plans`, {
    params,
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

export const loyaltyPlanServices = {
  createNewPlan,
  editPlan,
  getPlan,
  getPlanStatistics,
  getPlanClients,
  getClientPoints,
  getCompletedPlans,
};
