import { Step } from '@wls-solucoes/lets-eat-types';
import { v4 as uuidv4 } from 'uuid';
import { loginStepServices } from '../../../shared/services/loginStep';
import { userService } from '../../../shared/services/user.service';
import {
  GenericAction,
  USER_ESTABLISHMENT_LOGIN,
  USER_EXPIRE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_SET_COMPLETED_STEPS,
} from '../../types';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';
import {
  getSuccessType,
  promiseWithProgressIndicator,
} from '../promiseHelpers';

/**
 * Utilizando o helper promiseWithProgressIndicator para automatizar a
 * tarefa de exibir um indicator de progresso;
 * */
export const userLogin = (
  email?: string,
  password?: string,
  token?: string
) => {
  return async (dispatch: any) => {
    let response;
    await promiseWithProgressIndicator(
      dispatch,
      USER_LOGIN,
      userService.login(email, password, token),
      (data) => {
        response = data;
        return data;
      }
    );

    return response;
  };
};

export const setUserLogin = (payload: any): any => {
  return async (dispatch: any) => {
    dispatch({ type: getSuccessType(USER_LOGIN), payload });
  };
};

export const userLogout = () => {
  return async (dispatch: any) => {
    dispatch({ type: USER_LOGOUT });
    userService.logout();
  };
};

export const handleUserTokenExpired = () => {
  return async (dispatch: any) => {
    dispatch({ type: USER_EXPIRE });
    userService.logout();
  };
};

export const loadEstablishmentLoginToken = (token: string, user?: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: USER_ESTABLISHMENT_LOGIN,
      payload: { token, user },
    });
  };
};

export const setCompletedSteps = (step: Step) => {
  return async (dispatch: any) => {
    await promiseWithProgressIndicator(
      dispatch,
      USER_SET_COMPLETED_STEPS,
      loginStepServices.sendStep(step),
      ({ data }) => data
    );
  };
};

/**
 * Realizando manualmente a tarefa de exibir um indicator de progresso;
 * */
export const userLogin2 = (email: string, password: string) => {
  return async (dispatch: any) => {
    /**
     * Para exibir um indicador de progresso é necessário criar um id único
     * para em seguida disparar a action progressIndicatorAdd.
     * */
    const id = uuidv4();

    try {
      dispatch(progressIndicatorAdd(id));

      const response = await userService.login(email, password);

      /**
       * Cria a action de sucesso da promise
       * e em seguida dispara a mesma.
       * */
      const action: GenericAction = {
        type: getSuccessType(USER_LOGIN),
        payload: response,
      };
      dispatch(action);

      /**
       * Após concluir a promise, é necessário remover o id informado anteriormente
       * com  a action progressIndicatorRemove.
       * */
      dispatch(progressIndicatorRemove(id));

      // appHistory.push('/panel')
    } catch (e) {
      /**
       * Caso ocorra algum erro é necessário remover o id, caso não faça a remoção
       * a aplicação ficará travada na tela do indicador de progresso.
       * */
      dispatch(progressIndicatorRemove(id));
    }
  };
};
