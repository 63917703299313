import { createSlice } from '@reduxjs/toolkit';
import { AiqfomeState } from '../../types';
import { fetchAiqfomeConfiguration } from './actions';

const initialState: AiqfomeState = {};

export const aiqfomeSlice = createSlice({
  name: 'aiqfome',
  initialState,
  reducers: {
    setAiqfomeConfiguration(state, { payload }) {
      state.aiqfomeConfiguration = payload;
    },
  },
  extraReducers: {
    [fetchAiqfomeConfiguration.fulfilled.type]: (state, { payload }) => {
      state.aiqfomeConfiguration = payload;
    },
  },
});

export default aiqfomeSlice.reducer;
export const { setAiqfomeConfiguration } = aiqfomeSlice.actions;
