import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportServices } from '../../../shared/services/reports';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchGeneralReports = createAsyncThunk(
  'reports/fetchGeneralReports',
  async ({ startDate, endDate }: any, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices.getGeneralReports(startDate, endDate).finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchItemsReports = createAsyncThunk(
  'reports/fetchItemsReports',
  async ({ startDate, endDate }: any, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices.getItemsReports(startDate, endDate).finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchPersonsReports = createAsyncThunk(
  'reports/fetchPersonsReports',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices.getPersonsReports().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchDeliveriesReports = createAsyncThunk(
  'reports/fetchDeliveriesReports',
  async ({ startDate, endDate }: any, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices
      .getDeliveriesReports(startDate, endDate)
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const fetchSurveyReports = createAsyncThunk(
  'reports/fetchSurveyReports',
  async ({ startDate, endDate }: any, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices.getSurveyReports(startDate, endDate).finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchSurveyGeneralReports = createAsyncThunk(
  'reports/fetchSurveyGeneralReports',
  async ({ startDate, endDate }: any, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices
      .getGeneralSurveyReports(startDate, endDate)
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const fetchPizzaReports = createAsyncThunk(
  'reports/fetchPizzaReports',
  async ({ startDate, endDate }: any, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return reportServices.getPizzaReports(startDate, endDate).finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
