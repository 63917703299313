import { OpenCloseHour } from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import Api from './api';

const baseURL = 'api/OpeningHoursSettings';

function getOpeningHoursSettings(): Promise<AxiosResponse> {
  return Api.get(baseURL, {
    headers: {
      authHeader: true,
      'x-api-version': 3,
    },
  }).then((res) => res.data);
}

function getOpeningHoursSettingsWithGuid(
  establishmentGuid: string
): Promise<AxiosResponse> {
  return Api.get(`${baseURL}/${establishmentGuid}`, {
    headers: {
      'x-api-version': 3,
    },
  }).then((res) => res.data);
}

function updateOpeningHoursSettings(updatedOpeningHours: {
  openCloseHours: OpenCloseHour[];
  open24Hours: boolean;
}): Promise<AxiosResponse> {
  return Api.put(baseURL, updatedOpeningHours, {
    headers: {
      authHeader: true,
      'x-api-version': 3,
      successMessage: 'Horário de funcionamento salvo com sucesso.',
    },
  });
}

export const openingHoursSettingsService = {
  getOpeningHoursSettings,
  getOpeningHoursSettingsWithGuid,
  updateOpeningHoursSettings,
};
