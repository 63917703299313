import { makeStyles } from '@mui/styles';
import React from 'react';
import { env } from '../../../env';

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'calc(100dvh - 201px)',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RouteProgressIndicator: React.FC = () => {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <div className="full-page-loader">
        <img
          alt="Logo ComprAqui"
          src={env.REACT_APP_LOGO_URL}
          width="260"
          height="45"
        />
      </div>
    </div>
  );
};

export default RouteProgressIndicator;
