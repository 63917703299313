import { createAsyncThunk } from "@reduxjs/toolkit";
import { openingHoursSettingsService } from "../../../shared/services/openingHoursSettings";

export const getOpeningHoursSettings = createAsyncThunk(
  'openingHoursSettings/get',
  async () => {
    return openingHoursSettingsService.getOpeningHoursSettings()
  }
);

export const getOpeningHoursSettingsWithGuid = createAsyncThunk(
  'openingHoursSettings/get',
  async (establishmentGuid: string) => {
    return openingHoursSettingsService.getOpeningHoursSettingsWithGuid(establishmentGuid);
  }
);
