import { createSlice } from '@reduxjs/toolkit';
import { ReferralData } from '@wls-solucoes/lets-eat-types';
import { fetchReferralData } from './actions';

interface ReferralProgramState {
  isFetchingData: boolean;
  referralData?: ReferralData;
}

const initialState: ReferralProgramState = {
  isFetchingData: false,
};

const referralProgramSlice = createSlice({
  name: 'referralProgram',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchReferralData.pending.type]: (state) => {
      state.isFetchingData = true;
    },
    [fetchReferralData.fulfilled.type]: (state, { payload }) => {
      state.isFetchingData = false;
      state.referralData = payload;
    },
    [fetchReferralData.rejected.type]: (state) => {
      state.isFetchingData = false;
    },
  },
});

export default referralProgramSlice.reducer;
