import { createAsyncThunk } from '@reduxjs/toolkit';
import { aiqfomeService } from '../../../shared/services/aiqfome';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchAiqfomeConfiguration = createAsyncThunk(
  'aiqfome/fetchAiqfomeConfiguration',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return aiqfomeService.getConfiguration().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
