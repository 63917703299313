import {
  Person,
  PersonPaginatedData,
  PersonRequestForm,
} from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import {
  FetchPaginatedPersonsRequestParams,
  PersonAddressRequestParams,
} from '../../redux/types';
import Api from './api';

const base = '/api/person';

function getPaginated(
  reqParams: FetchPaginatedPersonsRequestParams
): Promise<AxiosResponse<PersonPaginatedData>> {
  return Api.get<PersonPaginatedData>(base, {
    params: reqParams,
    headers: {
      authHeader: true,
      'x-api-version': 3,
    },
  });
}

function addPerson(payload: PersonRequestForm): Promise<AxiosResponse<Person>> {
  return Api.post<Person>(base, payload, {
    headers: { authHeader: true, 'x-api-version': 3 },
  });
}

function updatePerson(payload: Person): Promise<AxiosResponse<Person>> {
  return Api.put<Person>(base, payload, {
    headers: { authHeader: true, 'x-api-version': 3 },
  });
}

function addPersonAddress({
  address,
  personGuid,
}: PersonAddressRequestParams): Promise<AxiosResponse<Person>> {
  return Api.post(`${base}/${personGuid}/addresses`, address, {
    headers: { authHeader: true, 'x-api-version': 3 },
  });
}

function updatePersonAddress({
  address,
  personGuid,
}: PersonAddressRequestParams): Promise<AxiosResponse<Person>> {
  return Api.put(`${base}/${personGuid}/addresses`, address, {
    headers: { authHeader: true, 'x-api-version': 3 },
  });
}

function deletePerson(personGuid: string): Promise<AxiosResponse> {
  return Api.delete(`${base}/${personGuid}`, {
    headers: { authHeader: true, 'x-api-version': 3 },
  });
}

export const personService = {
  getPaginated,
  addPerson,
  updatePerson,
  addPersonAddress,
  updatePersonAddress,
  deletePerson,
};
