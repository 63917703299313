import {
  GenericAction,
  UserState,
  USER_ESTABLISHMENT_LOGIN,
  USER_EXPIRE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_SET_COMPLETED_STEPS,
} from '../../types';
import { getSuccessType } from '../promiseHelpers';

const initialState: UserState = {};

export const userReducer = (
  state: UserState = initialState,
  action: GenericAction
): UserState => {
  switch (action.type) {
    case getSuccessType(USER_LOGIN):
      return {
        ...state,
        ...action.payload,
      };
    case USER_ESTABLISHMENT_LOGIN:
      return {
        ...state,
        accessToken: action.payload.token,
        user: action.payload.user || state.user,
        preLoginToken: undefined,
      };
    case getSuccessType(USER_SET_COMPLETED_STEPS):
      return {
        ...state,
        user: { ...state.user, completedSteps: action.payload },
      };
    case USER_LOGOUT:
      return initialState;
    case USER_EXPIRE:
      return initialState;
    default:
      return state;
  }
};
