export enum Step {
  firstLogin = 'first_login',
  productGuide = 'product_guide',
  categoriesGuide = 'categories_guide',
  productRegistrationGuide = 'product_registration_guide',
  optionalFormGuide = 'optional_form_guide',
  logsGuide = 'logs_guide',
  couponsGuide = 'coupons_guide',
  teamGuide = 'team_guide',
  panelGuide = 'panel_guide',
  qztrayGuide = 'qztray_guide',
  firstLoginRoute = 'first_login_route',
  desktopAndroidAppBannerV1 = 'desktop_android_app_banner_v1',
  mobileAndroidAppBannerV1 = 'mobile_android_app_banner_v1',
  pwPersons1 = 'pw_persons_1',
  desktopOrdersAppBannerV1 = 'desktop_orders_app_banner_v1',
}
