import { createAsyncThunk } from '@reduxjs/toolkit';
import { userPermissionService } from '../../../shared/services/api';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchUserPermissions = createAsyncThunk(
  'userPermission/fetchUserPermissions',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return userPermissionService.getAll().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
