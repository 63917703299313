import { CatalogState, CATALOG_GET_CATEGORIES, CATALOG_GET_PROMOTIONS, CATALOG_SAVE_PAGE_STATE, GenericAction } from "../../types";
import { getSuccessType } from "../promiseHelpers";


const initState: CatalogState = {};

const catalogReducer = (state = initState, action: GenericAction): CatalogState => {

  switch (action.type) {
    case CATALOG_GET_CATEGORIES:
    case getSuccessType(CATALOG_GET_CATEGORIES):
      return {
        ...state,
        catalog: action.payload
      }
    
    case CATALOG_SAVE_PAGE_STATE:
      return {
        ...state,
        openAccordions: action.payload,
      };
    
    case getSuccessType(CATALOG_GET_PROMOTIONS):
      return {
        ...state,
        promotionList: action.payload
      }
    
    default:
      return state;
  }

}

export default catalogReducer;
