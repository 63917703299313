import { createAsyncThunk } from '@reduxjs/toolkit';
import { printGroupSettingsService } from '../../../shared/services/api';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchPrintGroupsSettings = createAsyncThunk(
  'printGroups/fetchPrintGroupsSettings',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return printGroupSettingsService.getSettings().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchAvailableItems = createAsyncThunk(
  'printGroups/fetchAvailableItems',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return printGroupSettingsService.getItems().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
