import { createSlice } from '@reduxjs/toolkit';
import { NotificationsState } from '../../types';
import { fetchNotifications } from './actions';

const initialState: NotificationsState = {
  isFetching: false,
  showNewNotificationPopup: false,
  userNotificationsPaginatedData: {
    currentPage: 1,
    hasMore: false,
    pageSize: 10,
    result: [],
    totalItems: 0,
    totalPages: 1,
    unreadItems: 0,
  },
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setShowNewNotificationPopup: (state, { payload }) => {
      state.showNewNotificationPopup = payload;
    },
  },
  extraReducers: {
    [fetchNotifications.pending.type]: (state) => {
      state.isFetching = true;
    },
    [fetchNotifications.fulfilled.type]: (state, { payload }) => {
      state.userNotificationsPaginatedData = payload;
      state.isFetching = false;
    },
    [fetchNotifications.rejected.type]: (state) => {
      state.isFetching = false;
    },
  },
});

export default notificationsSlice.reducer;
export const { setShowNewNotificationPopup } = notificationsSlice.actions;
