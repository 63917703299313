import { createSlice } from '@reduxjs/toolkit';
import { ThemeState } from '../../types';
import { simpleStorageService } from '../../../shared/services/api';

export const THEME_STORAGE_KEY = 'web_theme_v1';

export const getDefaultTheme = () => {
  if (!window.matchMedia) return 'light';

  if (window.matchMedia('(prefers-color-scheme: dark)').matches) return 'dark';
  return 'light';
};

const initialState: ThemeState = {
  colors: {
    primaryColor: '#3DA865',
    secondaryColor: '#0366DE',
  },
  mode: getDefaultTheme(),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    /**
     * Like in H.P., calling this spell will make things light up.
     *
     * @param state - the current state
     */
    lumos(state, { payload = true }) {
      state.mode = 'light';
      if (payload) {
        simpleStorageService.setKey(THEME_STORAGE_KEY, {
          theme: 'light',
        });
      }
    },
    /**
     * Call this spell, and everything will be thrown into darkness.
     *
     * @param state - the current state
     */
    nox(state, { payload = true }) {
      state.mode = 'dark';
      if (payload) {
        simpleStorageService.setKey(THEME_STORAGE_KEY, {
          theme: 'dark',
        });
      }
    },
  },
});

export default themeSlice.reducer;
export const { lumos, nox } = themeSlice.actions;
