import { createAsyncThunk } from '@reduxjs/toolkit';
import { SurveyBase } from '@wls-solucoes/lets-eat-types';
import { surveyService } from '../../../shared/services/api';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchSurvey = createAsyncThunk(
  'survey/fetchSurvey',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return surveyService
      .getSurvey()
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);

export const createSurvey = createAsyncThunk(
  'survey/createSurvey',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return surveyService
      .createSurvey()
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);

export const updateSurveyStatus = createAsyncThunk(
  'survey/createSurvey',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return surveyService
      .updateSurveyStatus()
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);

export const updateSurvey = createAsyncThunk(
  'survey/updateSurvey',
  async (data: SurveyBase, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return surveyService
      .updateSurvey(data)
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);
