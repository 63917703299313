/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { RootState } from './types';

const reduxDevtools = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
  : (f: any) => f;

// const store = composeStoreWithMiddleware(rootReducer, reduxDevtools);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: RootState) => {
    // eslint-disable-next-line
    const { user } = state?.userReducer;
    if (user?.person !== undefined) {
      scope.setUser({
        username: `${user.person?.name ?? ''} ${user.person?.lastName ?? ''}`,
        email: user.email,
      });
    }
  },
});
const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), sentryReduxEnhancer, reduxDevtools)
);

const persistor = persistStore(store);

export { store, persistor };
