import { createSlice } from '@reduxjs/toolkit';
import { Kitchen } from '@wls-solucoes/lets-eat-types';
import { fetchKitchens } from './actions';

interface KitchenState {
  kitchens?: Kitchen[];
}

const initialState: KitchenState = {};

export const kitchenSlice = createSlice({
  name: 'kitchen',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchKitchens.fulfilled.type]: (state, { payload }) => {
      state.kitchens = payload;
    },
  },
});

export default kitchenSlice.reducer;
