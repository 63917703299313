import { createSlice } from '@reduxjs/toolkit';
import { FeeByAreaState } from '../../types';
import { feeByAreaGet } from './actions';

const initialState: FeeByAreaState = { feeByArea: [] };

export const feeByAreaSlice = createSlice({
  name: 'feeByArea',
  initialState,
  reducers: {},
  extraReducers: {
    [feeByAreaGet.fulfilled.type]: (state, { payload }) => {
      state.feeByArea = payload;
    },
  },
});

export default feeByAreaSlice.reducer;
