import {
  DeliveriesReportsResult,
  GeneralReportsResult,
  GeneralSurveyReports,
  ItemsReportsResult,
  PersonsReport,
  PizzaReports,
  ReportsBase,
  SurveyReportsResult,
} from '@wls-solucoes/lets-eat-reports';
import { DayOfTheWeek } from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import Api from './api';

const baseUrl = '/api/Reports';

async function getGeneralReports(
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportsBase<GeneralReportsResult>>> {
  return Api.get(`${baseUrl}/general`, {
    params: {
      startDate,
      endDate,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getPersonsReports(): Promise<AxiosResponse<PersonsReport>> {
  return Api.get(`${baseUrl}/persons`, {
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data.result);
}

async function getItemsReports(
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportsBase<ItemsReportsResult>>> {
  return Api.get(`${baseUrl}/items`, {
    params: {
      startDate,
      endDate,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getDeliveriesReports(
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportsBase<DeliveriesReportsResult>>> {
  return Api.get(`${baseUrl}/deliveries`, {
    params: {
      startDate,
      endDate,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getSurveyReports(
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportsBase<SurveyReportsResult>>> {
  return Api.get(`${baseUrl}/surveys`, {
    params: {
      startDate,
      endDate,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getGeneralSurveyReports(
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportsBase<GeneralSurveyReports>>> {
  return Api.get(`${baseUrl}/surveys/general`, {
    params: {
      startDate,
      endDate,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getDeliveryMen(
  deliveryManGuid: string,
  startDate: string,
  endDate: string
): Promise<any> {
  return Api.get(`${baseUrl}/deliveries/delivery-men/${deliveryManGuid}`, {
    params: {
      startDate,
      endDate,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

async function getPizzaReports(
  startDate: string,
  endDate: string,
  dayOfWeek?: DayOfTheWeek
): Promise<PizzaReports> {
  return Api.get(`${baseUrl}/pizzas`, {
    params: {
      startDate,
      endDate,
      dayOfWeek,
    },
    headers: {
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

export const reportServices = {
  getGeneralReports,
  getPersonsReports,
  getItemsReports,
  getDeliveriesReports,
  getSurveyReports,
  getGeneralSurveyReports,
  getDeliveryMen,
  getPizzaReports,
};
