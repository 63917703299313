/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  PersonFilterBy,
  PersonReducerState,
  PersonSortBy,
} from '../../../shared/models/person';
import { fetchPaginatedPerson } from './actions';

const initialState: PersonReducerState = {
  personPaginatedData: {
    result: [],
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    query: '',
    sortBy: PersonSortBy.nameAscendant,
    personFilterBy: PersonFilterBy.all,
  },
};

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    clearFetchDataStatus(state) {
      state.fetchDataStatus = undefined;
    },
  },
  extraReducers: {
    [fetchPaginatedPerson.pending.type]: (state) => {
      state.fetchDataStatus = 'pending';
    },
    [fetchPaginatedPerson.fulfilled.type]: (state, { payload }) => {
      state.fetchDataStatus = 'fulfilled';

      state.personPaginatedData = {
        ...payload.data,
        query: payload.data.query ?? '',
      };
    },
    [fetchPaginatedPerson.rejected.type]: (state) => {
      state.fetchDataStatus = 'rejected';
    },
  },
});

export const { clearFetchDataStatus } = personSlice.actions;
export default personSlice.reducer;
