import { Backdrop, Box, Card, LinearProgress, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { env } from '../../../env';
import { RootState } from '../../../redux/types';

interface ProgressIndicatorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: ReactElement<any, any>;
}

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(71, 176, 98, 0.5)',
  },
  barColorPrimary: {
    backgroundColor: '#47b062',
  },
})(LinearProgress);

const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props) => {
  const ids = useSelector(
    (state: RootState) => state.progressIndicatorReducer.onFetchingData
  );

  const { children } = props;

  return (
    <div>
      <Backdrop style={{ zIndex: 9999 }} open={(ids?.length ?? 0) > 0}>
        <Card>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            pl={4}
            pr={4}
          >
            <div className="full-page-loader">
              <img
                alt="Logo ComprAqui"
                src={env.REACT_APP_LOGO_URL}
                width="160"
                height="28"
              />
            </div>
            {/* <l color="primary" /> */}
            <Box pt={3} mb={1} ml={3} mr={3} mt={5}>
              <Typography variant="body2">Por favor aguarde...</Typography>
            </Box>
          </Box>
          <StyledLinearProgress />
        </Card>
      </Backdrop>
      {children}
    </div>
  );
};

export default ProgressIndicator;
