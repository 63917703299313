import { createAsyncThunk } from '@reduxjs/toolkit';
import { timeZoneService } from '../../../shared/services/api';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchTimeZones = createAsyncThunk(
  'timeZone/fetchTimeZones',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return timeZoneService
      .getAll()
      .finally(() => dispatch(progressIndicatorRemove(requestId)));
  }
);
