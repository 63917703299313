import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CashFlowConfig,
  CashFlowPaginatedRequestParams,
} from '@wls-solucoes/lets-eat-types';
import { MovementRequestParams } from '../../../shared/models/cashFlow';
import { cashFlowService } from '../../../shared/services/api';
import { cashFlowServices } from '../../../shared/services/cashFlow';
import {
  progressIndicatorAdd,
  progressIndicatorRemove,
} from '../progressIndicator/progressIndicatorActions';

export const fetchCashFlowHistory = createAsyncThunk(
  'cashFlow/fetchHistory',
  async (params: CashFlowPaginatedRequestParams) => {
    return cashFlowService.getHistory({ ...params, pageSize: 10 });
  }
);

export const fetchCashFlowHistoryFilters = createAsyncThunk(
  'cashFlow/fetchHistoryFilters',
  async () => {
    return cashFlowService.getFilters();
  }
);

export const fetchOpenedCash = createAsyncThunk(
  'cashFlow/fetchOpenedCash',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return cashFlowService.getOpenCashFlow().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const fetchCashFlowDetails = createAsyncThunk(
  'cashFlow/fetchCashFlowDetails',
  async (params: string, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return cashFlowService.getByGuid(params).finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const addCashFlowMovement = createAsyncThunk(
  'cashFlow/addCashFlowMovement',
  async (params: MovementRequestParams, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return cashFlowServices
      .addNewMovement(params)
      .then(() => {
        dispatch(fetchOpenedCash());
      })
      .finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
  }
);

export const deleteCashFlowMovement = createAsyncThunk(
  'cashFlow/deleteCashFlowMovement',
  async (params: string, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return cashFlowServices.deleteMovement(params).then(() => {
      dispatch(fetchOpenedCash()).finally(() => {
        dispatch(progressIndicatorRemove(requestId));
      });
    });
  }
);

export const fetchCashFlowConfig = createAsyncThunk(
  'cashFlow/fetchCashFlowConfig',
  async (_, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return cashFlowService.getConfig().finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);

export const updateCashFlowConfig = createAsyncThunk(
  'cashFlow/updateCashFlowConfig',
  async (data: CashFlowConfig, { dispatch, requestId }) => {
    dispatch(progressIndicatorAdd(requestId));

    return cashFlowService.updateConfig(data).finally(() => {
      dispatch(progressIndicatorRemove(requestId));
    });
  }
);
