import { ItemCheckout } from '../../../shared/models/order/checkout/orderCheckout';
import {
  GenericAction,
  OrderState,
  ORDER_CHECKOUT_ADD_ADDRESSEE_INFORMATION,
  ORDER_CHECKOUT_ADD_COUPON,
  ORDER_CHECKOUT_ADD_DELIVERY_ADDRESS,
  ORDER_CHECKOUT_ADD_DELIVERY_FEE,
  ORDER_CHECKOUT_ADD_DELIVERY_TYPE,
  ORDER_CHECKOUT_ADD_ESTABLISHMENT_PATH_NAME,
  ORDER_CHECKOUT_ADD_ITEM,
  ORDER_CHECKOUT_ADD_ORDER_DETAILS,
  ORDER_CHECKOUT_ADD_PAYMENT,
  ORDER_CHECKOUT_REMOVE_COUPON,
  ORDER_CHECKOUT_REMOVE_DELIVERY_ADDRESS,
  ORDER_CHECKOUT_REMOVE_DELIVERY_FEE,
  ORDER_CHECKOUT_REMOVE_ITEM,
  ORDER_GET_DETAILS,
  ORDER_GET_PAGINATED,
  ORDER_REMOVE_DETAILS,
  ORDER_RESET_NEW_ITEM_ADDED_INDICATOR
} from '../../types';
import { getSuccessType } from '../promiseHelpers';

const initState: OrderState = {
  newItemAdded: false
};

const OrderReducer = (
  state: OrderState = initState,
  action: GenericAction
): OrderState => {
  switch (action.type) {
    case getSuccessType(ORDER_GET_PAGINATED):
      return {
        ...state,
        orders: action.payload,
      };
    case getSuccessType(ORDER_GET_DETAILS):
      return {
        ...state,
        orderDetails: action.payload,
      };
    case ORDER_REMOVE_DETAILS:
      return {
        ...state,
        orderDetails: undefined,
      };

    case getSuccessType(ORDER_CHECKOUT_ADD_ORDER_DETAILS):
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          payment: undefined,
          delivery: {
            addressee: state.orderCheckout?.delivery?.addressee,
            whatsApp: state.orderCheckout?.delivery?.whatsApp,
          },
          items: undefined,
          orderCoupon: undefined,
          discountCode: undefined,
        },
      };
    case ORDER_CHECKOUT_ADD_ITEM:
      sessionStorage.setItem('order', JSON.stringify(state));
      return {
        ...state,
        newItemAdded: true,
        orderCheckout: {
          ...state.orderCheckout,
          items: [
            ...(state.orderCheckout?.items ?? new Array<ItemCheckout>()),
            action.payload,
          ],
        },
      };
    case ORDER_CHECKOUT_REMOVE_ITEM:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          items: state.orderCheckout?.items?.filter(
            (item) => item !== action.payload
          ),
        },
      };
    case ORDER_CHECKOUT_ADD_PAYMENT:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          payment: action.payload,
        },
      };
    case ORDER_CHECKOUT_ADD_DELIVERY_TYPE:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          delivery: {
            ...state.orderCheckout?.delivery,
            type: action.payload,
          },
        },
      };
    case ORDER_CHECKOUT_ADD_DELIVERY_FEE:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          delivery: {
            ...state.orderCheckout?.delivery,
            neighborhoodFeeGuid: action.payload.neighborhoodFeeGuid,
            fee: action.payload.fee,
            averageTime: action.payload.averageTime,
          },
        },
      };
    case ORDER_CHECKOUT_REMOVE_DELIVERY_FEE:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          delivery: {
            ...state.orderCheckout?.delivery,
            neighborhoodFeeGuid: undefined,
            fee: undefined,
          },
        },
      };
    case ORDER_CHECKOUT_ADD_DELIVERY_ADDRESS:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          delivery: {
            ...state.orderCheckout?.delivery,
            address: action.payload,
          },
        },
      };
    case ORDER_CHECKOUT_REMOVE_DELIVERY_ADDRESS:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          delivery: {
            ...state.orderCheckout?.delivery,
            address: undefined,
          },
        },
      };
    case ORDER_CHECKOUT_ADD_ESTABLISHMENT_PATH_NAME:
      return state.orderCheckout?.establishmentPathName === action.payload
        ? state
        : {
            ...state,
            orderCheckout: {
              ...state.orderCheckout,
              payment: undefined,
              delivery: {
                addressee: state.orderCheckout?.delivery?.addressee,
                whatsApp: state.orderCheckout?.delivery?.whatsApp,
              },
              items: undefined,
              establishmentPathName: action.payload,
              orderCoupon: undefined,
              discountCode: undefined,
            },
          };
    case ORDER_CHECKOUT_ADD_ADDRESSEE_INFORMATION:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          delivery: {
            ...state.orderCheckout?.delivery,
            whatsApp: action.payload.whatsApp,
            addressee: action.payload.addressee,
            document: action.payload.document,
          },
        },
      };
    case getSuccessType(ORDER_CHECKOUT_ADD_COUPON):
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          orderCoupon: action.payload,
          discountCode: action.payload.guid,
        },
      };
    case ORDER_CHECKOUT_REMOVE_COUPON:
      return {
        ...state,
        orderCheckout: {
          ...state.orderCheckout,
          orderCoupon: undefined,
          discountCode: undefined,
        },
      };
    case ORDER_RESET_NEW_ITEM_ADDED_INDICATOR:
      return {
        ...state,
        newItemAdded: false
      };
    default:
      return state;
  }
};

export default OrderReducer;
